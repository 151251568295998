import React, { useMemo } from 'react';
import Section from '../../common/section/section';
import Panel from '../../common/panel/panel';
import styles from '../../item-panel/item-panel.module.css';
import sectionStyle from '../../common/section/section.module.css';

import { ColorPicker, theme, Flex, Collapse, Divider, Typography } from 'antd';
import Incrementer from '../../common/incrementer';
import collapseStyle from '../drawpanel/drawPanel.module.css'; // Import styles from drawPanel
import tinycolor from 'tinycolor2';
import Renders from '../../common/renders/renders';
import { useParams } from 'react-router-dom';
import { videos } from '../../../data/videocards';
import { useAuthContext } from '../../../hooks/contextHooks/useAuthContext';
import { convertMetersToFeet } from '../../../functions/helper';
import { useSettingContext } from '../../../hooks/contextHooks/useSettingsContext';

export default function Selectpanel({ stroke, setStroke, lineThickness, setLineThickness, lineLength, setLineLength, deleteState, setDeleteState, rightPanelOpen, screenshots, setScreenshots }) {
    // Hooks
    const { token } = theme.useToken();
    const [color, setColor] = React.useState(stroke ?? token.colorPrimary);
    const { id } = useParams();
    const {measurementSystem} = useSettingContext();
    const {user} = useAuthContext();

    const handleColorChange = (e) => {
        const hexColor = tinycolor(e.metaColor.originalInput).toHexString();
        setColor(hexColor);
        setStroke(hexColor);
    }

    // Function to handle deletion
    const handleDelete = () => {
        setDeleteState(true);
    }

    const measurementSystemText = useMemo(() => {
        if(measurementSystem !== 'ft'){
            return " (cm)";
        }
        else {
            return " (inch)"
        }
    },[measurementSystem]);

    const panel = () => {
        return (
            <Flex vertical gap={10}>
                <Section className={sectionStyle.incrementer}>
                    <Incrementer
                        name={"Thickness" + measurementSystemText}
                        data={lineThickness}
                        increment={() => setLineThickness(lineThickness + 1)}
                        decrement={() => setLineThickness(lineThickness - 1)}
                        adjustValue={(value) => setLineThickness(value)}
                    />
                </Section>
                <Section className={sectionStyle.incrementer}>
                    <Incrementer
                        name={`Length ` + (measurementSystem !== 'ft' ? '(cm)' : '(ft)')}
                        data={measurementSystem !== 'ft' ? Math.floor(lineLength) : convertMetersToFeet(Math.floor(lineLength))}
                        increment={() => setLineLength(lineLength + 1)}
                        decrement={() => setLineLength(lineLength - 1)}
                        adjustValue={(value) => setLineLength(value)}
                    />
                </Section>
                <Section>
                    {/* Content */}
                    <Flex className={sectionStyle.flexbox}>
                        <span> Material </span>
                        <ColorPicker value={color} onChange={handleColorChange} />
                    </Flex>
                </Section>
                {/* Content */}
                {/*
                        <Section>
                            <VideoBox />
                        </Section>
                    */}
                {screenshots.length > 0 && <Renders
                    projectId={id}
                    screenshots={screenshots}
                    setScreenshots={setScreenshots}
                />}
                <Section>
                    <Typography variant='h5'>{videos[2].title}</Typography>
                    <Divider style={{ marginBottom: 10, marginTop: 10 }} />
                    <iframe
                        className={styles.videoSection}
                        src={videos[2].video}
                        title="Video player"
                        controls // Adding controls so the user can play manually
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                    ></iframe>
                </Section>
            </Flex>
        )
    }

    const collapseItem = [
        {
            key: '1',
            label: <span className={styles.title}> Selected Wall </span>,
            children: <>{panel()}</>,
        }
    ]


    return (
        <Panel className={rightPanelOpen ? styles.itemPanel : styles.itemPanelClosed}>
            {/*rightPanelOpen && (
                <Collapse
                    items={collapseItem}
                    bordered={false}
                    className={collapseStyle.collapse}
                    expandIconPosition={'end'}
                    defaultActiveKey={['1']}
                />
            )*/}

            {rightPanelOpen &&
                <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                    <span className={styles.title}> Selected Wall </span>
                    {panel()}
                </div>
            }




        </Panel>
    )
}
