import * as turf from '@turf/turf';
import MD5 from 'crypto-js/md5';

import cloneDeep from 'lodash/cloneDeep';
const getPoint = (points, id) => {
    let index = points.findIndex(point => point?.id === id);
    if (index === -1) return null;
    return points[index];
};

export const findAdjacencyList = (walls, points) => {
    const adjacencyList = {};

    points.forEach(point => {
        adjacencyList[point.id] = [];
    });

    walls.forEach(wall => {
        const startId = wall.startPointId;
        const endId = wall.endPointId;

        if (!adjacencyList[startId].includes(endId)) {
            adjacencyList[startId].push(endId);
        }
        if (!adjacencyList[endId].includes(startId)) {
            adjacencyList[endId].push(startId);
        }
    });

    return adjacencyList;
};

const cyclesEqual = (a, b) => {
        if (a.length !== b.length) return false;
        
        const setA = new Set(a);
        const setB = new Set(b);
        
        if (setA.size !== setB.size) return false;
        
        for (let val of setA) {
            if (!setB.has(val)) return false;
        }
        
        return true;
    };

const findAllRooms = (walls, points, adjacencyList) => {
    const allCycles = [];
    const edgeCounts = new Map();
    const getEdgeKey = (node1, node2) => {
        return node1 < node2 ? `${node1}-${node2}` : `${node2}-${node1}`;
    };
    
    const dfs = (node, startNode, path, visited) => {
       
        if (path.length > 0 && node === startNode && geometricFilter([...path, node], walls, points, adjacencyList)) {
            
            for(let i = 0; i < allCycles.length; i++){
                if(cyclesEqual([...path,node],allCycles[i])){
                    return;
                }
            }
            
            allCycles.push([...path, node]);
            for (let i = 0; i < path.length; i++) {
                const edgeKey = getEdgeKey(path[i], path[(i + 1) % path.length]);
                edgeCounts.set(edgeKey, (edgeCounts.get(edgeKey) || 0) + 1);
            }
            return;
        }
    
        if (visited.has(node)) return;
    
        visited.add(node);
        path.push(node);
    
        if (adjacencyList[node]) {
            for (const neighbor of adjacencyList[node]) {
                if (path.length > 1 && neighbor === path[path.length - 2]) continue;
    
                const edgeKey = getEdgeKey(node, neighbor);
                if (edgeCounts.get(edgeKey) >= 2) continue; 
                dfs(neighbor, startNode, path, visited);
            }
        }
    
        path.pop();
        visited.delete(node);
    };

    const dfsSimple = (node, startNode, path, visited) => {
        if (path.length > 0 && node === startNode) {
            allCycles.push([...path, node]);
            return;
        }

        if (visited.has(node)) return;

        visited.add(node);
        path.push(node);

        if (adjacencyList[node]) {
            for (const neighbor of adjacencyList[node]) {
                if (path.length > 1 && neighbor === path[path.length - 2]) continue;
                dfsSimple(neighbor, startNode, path, visited);
            }
        }

        path.pop();
        visited.delete(node);
    };
    
    for (const node in adjacencyList) {
        dfs(node, node, [], new Set());
    }

    
    const uniqueCycles = [];
    for (const cycle of allCycles) {
        let isUnique = true;
        for (const uniqueCycle of uniqueCycles) {
            if (cyclesEqual(cycle, uniqueCycle)) {
                isUnique = false;
                break;
            }
        }
        if (isUnique) {
            uniqueCycles.push(cycle);
        }
    }

    return uniqueCycles;
};
const reorderCycle = (cycle, walls) => {
    let orderedPoints = [];
    let visited = new Set();
    let currentPointId = cycle[0];
    let nextPointId = null;

    const connections = new Map();
    for (const wall of walls) {
        if (!connections.has(wall.startPointId)) connections.set(wall.startPointId, []);
        if (!connections.has(wall.endPointId)) connections.set(wall.endPointId, []);
        connections.get(wall.startPointId).push(wall.endPointId);
        connections.get(wall.endPointId).push(wall.startPointId);
    }

    while (visited.size < cycle.length) {
        orderedPoints.push(currentPointId);
        visited.add(currentPointId);

        let neighbors = connections.get(currentPointId).filter(id => cycle.includes(id) && !visited.has(id));
        if (neighbors.length > 0) {
            nextPointId = neighbors[0];
        } else {
            break;
        }

        currentPointId = nextPointId;
    }
    orderedPoints.push(orderedPoints[0]);
    return orderedPoints;
};

export const geometricFilter = (cycle, walls, points,   adjacencyList) => {
    const cycleSet = new Set(cycle);
    cycle.push(cycle[0]);
    let polygonCoords = cycle.map(pointId => {
        const point = getPoint(points, pointId);
        return [point.x, point.y];
    });

    const polygon = turf.polygon([polygonCoords]);
    const polygonEdges = new Set();
    for (let i = 0; i < cycle.length; i++) {
        const startId = cycle[i];
        const endId = cycle[(i + 1) % cycle.length];
        polygonEdges.add(`${startId}-${endId}`);
        polygonEdges.add(`${endId}-${startId}`);
    }
    for (const wall of walls) {
        const startId = wall.startPointId;
        const endId = wall.endPointId;
        const wallKey = `${startId}-${endId}`;
        const reverseWallKey = `${endId}-${startId}`;

        if (!polygonEdges.has(wallKey) && !polygonEdges.has(reverseWallKey)) {
            const start = getPoint(points, startId);
            const end = getPoint(points, endId);

            if (!start || !end) return false;

            const line = turf.lineString([[start.x, start.y], [end.x, end.y]]);

            if (turf.booleanPointInPolygon([(start.x + end.x) / 2, (start.y + end.y)/2], polygon)) {
                return false;
            }
        }
    }
    
    return true;
};

export const containsSubsetCycle = (cycle, allCycles) => {
    const cycleSet = new Set(cycle);
    for (const subCycle of allCycles) {
        const subCycleSet = new Set(subCycle);
        if (subCycle.length < cycle.length && [...subCycleSet].every(node => cycleSet.has(node))) {
            return true;
        }
    }
    return false;
};

export const findChordlessCycles = (adjacencyList, walls, points) => {
    const allCycles = findAllRooms(walls, points, adjacencyList);
    const reorderedCycles = allCycles;
    // reorderedCycles[1] = reorderCycle(reorderedCycles[1], walls);
    // let chordlessCycles = reorderedCycles.filter(cycle => geometricFilter(cycle, walls, points,adjacencyList));
    // let chordlessCycles = reorderedCycles.filter(cycle => !containsSubsetCycle(cycle, allCycles));
    
    let chordlessCycles = allCycles;
    return chordlessCycles;
};

// Function to calculate the centroid of a polygon
const calculateCentroid = (points) => {
    let xSum = 0;
    let ySum = 0;
    const numPoints = points.length / 2;

    for (let i = 0; i < points.length; i += 2) {
        xSum += points[i];
        ySum += points[i + 1];
    }

    return {
        x: xSum / numPoints,
        y: ySum / numPoints
    };
};

export const shrinkPoints = (points, shrinkFactor) => {
    const centroid = calculateCentroid(points);
    const newPoints = [];
    for (let i = 0; i < points.length; i += 2) {
        const x = points[i];
        const y = points[i + 1];

        const newX = centroid.x - x < 0 ? x - shrinkFactor : x + shrinkFactor;
        const newY = (centroid.y - y < 0) ? y - shrinkFactor : y + shrinkFactor;

        newPoints.push(newX, newY);
    }

    return newPoints;
};

/**
 * Function to Retrieve Points as Strings
 * @param {*} points 
 */
export const findPointList = (points) => {
    let roomStr = "";
    let roomIds = points;
    roomIds.forEach((room,index) => {
        if(index <= points.length / 3){
            roomStr += room.id;
        }
    })
    const hash = 'hash-' + MD5(roomStr).toString(); // Prefix with a valid character
    return roomStr
}

/**
 * This song detects item collision
 * @param {*Object} item1 
 * @param {*Object} item2 
 * @returns 
 */
export const detectItemCollision = (item1, item2) => {
    return !(
        item1.x + item1.width < item2.x || // item1 is to the left of item2
        item1.x > item2.x + item2.width || // item1 is to the right of item2
        item1.y + item1.height < item2.y || // item1 is above item2
        item1.y > item2.y + item2.height // item1 is below item2
    );
};
/**
 * 
 * @param {*} collidedItems 
 * @param {*} selectedItem 
 * @returns the smallest zIndex that is bigger than the selectedItems 
 */
export const  findNextZ = (collidedItems, selectedItem) => {
    // Filter the collided items to find those with a zIndex greater than the selectedItem's zIndex
    const higherZIndexes = collidedItems
        .map(item => item.zIndex)
        .filter(zIndex => zIndex >= selectedItem.zIndex);

    console.log(higherZIndexes);

    // Return the smallest zIndex found, or undefined if no such item exists
    return higherZIndexes.length > 0 ? Math.min(...higherZIndexes) : selectedItem.zIndex ;
}

export const findPreviousZ = (collidedItems, selectedItem) => {
    // Filter the collided items to find those with a zIndex smaller than the selectedItem's zIndex
    const lowerZIndexes = collidedItems
        .map(item => item.zIndex)
        .filter(zIndex => zIndex <= selectedItem.zIndex);
    // Return the largest zIndex found, or -1 if no such item exists
    return lowerZIndexes.length > 0 ? Math.max(...lowerZIndexes) : selectedItem.zIndex;
}
