import { createContext, useReducer } from 'react';

export const ScreenShotContext = createContext();

export const screenshotReducer = (state,action) => {
    switch(action.type){
        case 'SS_TRIGGER':
            return { ...state, trigger: state.ssTrigger + 1 }
        default:
            return state;
    }
}

export const ScreenshotContextProvider = ({children}) => {
    const [state,ssDispatch] = useReducer(screenshotReducer, {
        ssTrigger: 0
    })
    
    return (
        <ScreenShotContext.Provider value={{...state,ssDispatch}}>
            {children}
        </ScreenShotContext.Provider>
    )
}