// SearchBar.js

import React, { useState } from 'react';
import './searchbar.css'; // Import your search bar-specific styles
import { Flex } from 'antd';
import { SearchIcon } from '../icons/mainIcons/mainIcons';
import IconButton from '../buttons/iconButton';


const SearchBar = ({query,setQuery}) => {

    const handleInputChange = (event) => {
        const value = event.target.value;
        setQuery(value);
        
        // Resetting
        if(value === ""){
           
        }
    };

    const handleSearch = () => {
       
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <>
            <Flex align='center' style={{
                borderRadius: '8px',
                backgroundColor: '#F9F9F9',
                position: 'relative',
            }}>
                <input
                    placeholder='Search for an Item'
                    value={query}
                    style={{
                        borderRadius: '8px',
                        backgroundColor:'#C8C8C81A'
                    }}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                />
                <IconButton
                    clickFunction={handleSearch}
                    style={{
                        position: 'absolute',
                        right: '5px', // Position the icon at the rightmost corner
                        top: '50%', // Position the icon in the center vertically
                        transform: 'translateY(-50%)' // Adjust the vertical position to center the icon
                    }}>
                    <SearchIcon />
                </IconButton>


            </Flex>
        </>
    );
};

export default SearchBar;
