import { useContext } from "react"
import { SettingsContext } from "../../contexts/SettingsContext";

export const useSettingContext = () => {
    const context = useContext(SettingsContext);
    if(!context){
        throw Error('useSettingContext must be used inside an SettingsContext Provider ')
    }
    return context;

}