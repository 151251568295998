import {BathroomIcon, LivingRoomIcon,ChildRoomIcon, BedroomIcon ,DiningRoomIcon, OfficeIcon, MeetingRoomIcon, KitchenIcon} from '../../components/common/icons/roomIcons/roomIcons';
export const roomDialogMenu = [
    {
        id:1,
        img:(<LivingRoomIcon/>),
        name:"Living Room"
    },
    {
        id:2,
        img:(<KitchenIcon/>),
        name:"Kitchen"
    },
    {
        id:3,
        img:(<BathroomIcon/>),
        name:"Bathroom"
    },
    {
        id:4,
        img:(<ChildRoomIcon/>),
        name:"Children's Room"
    },
    {
        id:5,
        img:(<BedroomIcon/>),
        name:"Bedroom"
    },
    {
        id:6,
        img:(<DiningRoomIcon/>),
        name:"Dining Room"
    },
    {
        id:7,
        img:(<OfficeIcon/>),
        name:"Office"
    },
    {
        id:8,
        img:(<MeetingRoomIcon/>),
        name:"Meeting Room"
    }
]