import { createContext, useReducer } from 'react';

export const SnapShotContext = createContext();

export const snapshotReducer = (state, action) => {
    switch (action.type) {
        case 'INITIALIZE_SNAPSHOT':
            if(action.payload){
                const initialSnapshot = action.payload;
                return {
                    ...state,
                    snapshot:initialSnapshot,
                    index:initialSnapshot.length > 0 ? initialSnapshot.length - 1: 0
                };
            }
            else{
                return state;
            }
        case 'SNAPSHOT':
            const newSnapshot = [...state.snapshot.slice(0, state.index + 1), action.payload];
            return {
                ...state,
                snapshot: newSnapshot,
                index: state.index + 1
            };
        case 'UNDO':
            return {
                ...state,
                index: Math.max(state.index - 1, 0)
            };
        case 'REDO':
            return {
                ...state,
                index: Math.min(state.index + 1, state.snapshot.length - 1)
            };
        default:
            return state;
    }
}

export const SnapShotContextProvider = ({ children }) => {
    const [state, snapshotDispatch] = useReducer(snapshotReducer, {
        snapshot: [],
        index: -1
    })

    return (
        <SnapShotContext.Provider value={{ ...state, snapshotDispatch }}>
            {children}
        </SnapShotContext.Provider>
    )
}