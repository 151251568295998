import React from 'react';
import styles from './iconButton.module.css';

export default function IconButton({ clickFunction,children, className, disabled, ...props }) {
  const combinedClassName = [styles.iconButton, className, disabled ? styles.disabled : ''].filter(Boolean).join(' ');
  return (
    <div className={combinedClassName} {...props} onClick={clickFunction}>
        {children}
    </div>
  )
}
