import React from 'react';

// styles
import styles from './panel.module.css';

export default function Panel({ children, className, ...props }) {
    return (
        <div className={[styles.panel, className].filter(Boolean).join(' ')} {...props}>
            {children}
        </div>
    );
}
