import React, { useMemo, useState } from 'react'
import Section from '../section/section';
import { Divider, Flex, Image, Typography } from 'antd';
import ScreenshotModal from '../../modals/screenshotModal';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import styles from './renders.module.css';
import IconButton from '../buttons/iconButton/iconButton';
function Renders({ projectId, screenshots, setScreenshots }) {
    const [index, setIndex] = useState(0);
    let firstImg = screenshots.filter((item) => item.project_id === projectId)[0];

    let memoizedSS = useMemo(() => {
        if (screenshots && screenshots.length > 0) {
            return screenshots.filter((item) => item.project_id === projectId)
        }
        else {
            return []
        }
    }, [screenshots]);
    if (memoizedSS.length > 0) {
        return (
            <Section>
                <Typography style={{
                    color: '#000233',
                    fontSize: '14px',
                }}> My Screenshots </Typography>
                <Divider style={{ marginTop: 5, marginBottom: 10 }} />
                <div style={{ display: 'none' }}>
                    <ScreenshotModal screenshots={memoizedSS} setScreenshots={setScreenshots} ssIndex={index} />
                </div>
                <Image
                    src={memoizedSS.length > 0 || index < memoizedSS.length && memoizedSS ? memoizedSS[index].src : ""}
                    onClick={() => {
                        // This triggers the preview of the image
                        document.getElementById(`hidden-img-${firstImg._id.toString()}`).click();
                    }}
                    height={140}
                    style={{
                        objectFit: 'cover'
                    }}
                    preview={false}
                />
                <Flex justify='space-between' align='center' style={{ marginTop: 10 }}>
                    <Typography>{index + 1} / {memoizedSS.length}</Typography>
                    <Flex gap={12}>
                        <IconButton disabled={index === 0} clickFunction={() => {
                            if (index - 1 <= 0) {
                                setIndex(0);
                            }
                            else {
                                setIndex(index - 1)
                            }
                        }}>
                            <LeftOutlined />
                        </IconButton>
                        <IconButton disabled={index === memoizedSS.length - 1} clickFunction={() => {
                            if (index + 1 >= memoizedSS.length) {
                                setIndex(memoizedSS.length - 1);
                            }
                            else {
                                setIndex(index + 1)
                            }

                        }}>
                            <RightOutlined className={styles.arrow} disabled={index === memoizedSS.length - 1} />
                        </IconButton>

                    </Flex>
                </Flex>
            </Section>
        )
    }

}

export default Renders
