import { useContext } from "react"
import { ProjectContext } from "../../contexts/ProjectContext"

export const useProjectContext = () => {
    const context = useContext(ProjectContext);
    if(!context){
        throw Error('useProjectContext must be used inside an ProjectContext Provider ')
    }
    return context;

}