import { Alert, Input, Modal } from 'antd'
import React, { useState } from 'react'
import VStack from '../../stack/vStack/vStack'
import { useAuthContext } from '../../../hooks/contextHooks/useAuthContext';
import { useUserAPI } from '../../../hooks/apiHooks/userHooks/useUserAPI';

export default function DeleteUserModal({ open, cancel }) {
    const [input, setInput] = useState("");
    const { user } = useAuthContext();
    const { deleteUser, error,message } = useUserAPI();


    const handleDelete = async () => {
        if (user.username === input) {
            await deleteUser(user.userIdMongo)
        }
    }

    return (
        <Modal okType='danger' okText='Delete' title="Warning" open={open} onOk={() => handleDelete()} onCancel={cancel}>
            <VStack>
                <Alert
                    type='warning'
                    message='Are you sure you want to delete the user'
                />

                <Input placeholder='Please enter the username to confirm deletion' onChange={(e) => setInput(e.target.value)} />
            </VStack>
            {error && <Alert type='danger' message={message}/>}
        </Modal>
    )
}
