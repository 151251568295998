import React from 'react'
import styles from './hStack.module.css';

export default function HStack(props) {
    return (
      <div className={styles.hStack}>
        {props.children}
      </div>
    );
}
