import React, { useEffect } from 'react'
import SigninForm from '../../components/forms/signinForm/signinForm'
import { Flex, Layout } from 'antd'
import styles from './signin.module.css';
import InteractiveSphere from '../../components/common/interactive-images/interactivesphere/interactiveSphere';



export default function SignIn() {
    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
    const hideComponents = screenWidth > 700;

    useEffect(() => {
        // Function to handle window resize and update screenWidth state
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Layout>
            <Flex justify='flex-start' align='center' style={{ height: "100vh" }}>
                {hideComponents && (
                    <InteractiveSphere />
                )}


                <Flex vertical align='center' justify='flex-start' style={{
                    height: "100vh",
                    width: hideComponents ? "50vw" : "100vw",
                    gap: 20,
                    padding: '7vh',
                }}>
                    <SigninForm />
                </Flex>

            </Flex>


        </Layout>
    )
}
