import React, { useState } from 'react'
import Section from '../../common/section/section'
import { Form, Button, Divider, Flex, Alert, Input } from 'antd'
import { GoogleOutlined } from '@ant-design/icons';
import styles from './signinform.module.css';
import { LoginIcon } from '../../common/icons/mainIcons/mainIcons';
import { useGoogleLogin } from '@react-oauth/google';
import { useAuthForm } from '../../../hooks/formHooks/useAuthForm';

export default function SigninForm() {
    // Hooks & Contexts
    const { googleLogin, error, message, emailLogin, messageVerification, timer, sendVerificationCode } = useAuthForm();
    const gLogin = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            // 'accessToken' is the OAuth 2.0 access token
            await googleLogin(tokenResponse);
        },
    });
    const [email, setEmail] = useState("");
    const [code, setCode] = useState("");

    return (

        <Section className={styles.section}>
            <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
            >
                <Form.Item>
                    <Flex justify='center' align='center' vertical gap={20}>
                        <LoginIcon />
                    </Flex>
                </Form.Item>
                <Form.Item >
                    <Flex justify='center' align='center' vertical>

                        <span className={styles.loginTitle}> Welcome to Decobot </span>
                        <span className={styles.loginSubTitle}> Log in  </span>
                        {/*  or register with your email */}
                    </Flex>
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        size='large'
                        className={styles.loginButtonGoogle}
                        icon={<GoogleOutlined />}
                        onClick={() => gLogin()}
                    >
                        Continue with Google
                    </Button>
                </Form.Item>
                

                
                    <Divider style={{ margin: 20 }} />
                    <Form.Item>
                    <Input placeholder='E-mail' className={styles.loginButton} onChange={(e) => setEmail(e.target.value)} />
                    </Form.Item>
                    {messageVerification && (
                    <Form.Item>
                        <Input placeholder='Code' className={styles.loginButton} onChange={(e) => setCode(e.target.value)}/>
                    </Form.Item>
                )}
                {messageVerification && timer >= 0 && (
                    <Form.Item>
                        <Alert message={"Remaining Seconds: " + timer} type={timer > 0 ? 'warning' : 'error'}/>
                        {timer === 0  && (<Button type="default" style={{marginTop:5}} onClick={() => sendVerificationCode(email)}> Refresh </Button>)}
                    </Form.Item>
                )}
                <Form.Item>
                    <Button type="default" className={styles.loginButton} onClick={() => emailLogin(email,code)} >
                        Continue
                    </Button>
                </Form.Item>
                

                {error && (<Alert message={message} type='error' />)}
            </Form>
        </Section>
    )
}
