import { Flex, Menu } from 'antd';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import styles from './rightClickMenu.module.css';
import {
  DeleteShortcut, CutShortcut, CopyShortcut, PasteShortcut,
  ZIndexIncreaseShortcut, ZIndexDecreaseShortcut,
  HFlip, VFlip,
  GroupShortcut,
  UngroupShortcut
} from '../common/icons/shortcutIcons/shortcutIcons';
import { detectItemCollision } from '../../functions/zoneFinder';


function RightClickMenu({ visible, position, onClose, selectedItemIndexes, handleDelete, cut, copy, paste, copyItems,groupedItems, selectedItem, items, transform, handleVerticalChange, handleHorizontalChange, zIndexIncrase, zIndexDecrease, handleGroup, handleUngroup }) {


  const handleClickOutside = (e) => {
    if (visible) {
      onClose();
    }
  };


  useEffect(() => {
    // Attach the event listener to detect clicks outside the menu
    document.addEventListener('onMouseUp', handleClickOutside);

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener('onMouseUp', handleClickOutside);
    };
  }, [visible]);

  const collidedItems = useMemo(() => {
    let arr = [];
    if (selectedItem) {
      items.forEach((item) => {
        if (detectItemCollision(item, selectedItem) && item.id !== selectedItem.id) {
          arr.push(item);
        }
      });
    }
    return arr;
  }, [items, selectedItem,transform]);

  const isSelectedItemOnTop = useMemo(() => {
    if (selectedItem && collidedItems.length > 0) {
      // Find the maximum zIndex among the collidedItems
      const maxZIndex = Math.max(...collidedItems.map(item => item.zIndex));
  
      if (selectedItem.zIndex > maxZIndex) {
        return true; // Selected item has a higher zIndex
      } else if (selectedItem.zIndex === maxZIndex) {
        // If zIndexes are equal, compare their index positions in the items array
        const selectedItemIndex = items.findIndex(item => item.id === selectedItem.id);
        const maxZIndexItems = collidedItems.filter(item => item.zIndex === maxZIndex);
        const maxZIndexItemIndex = Math.max(...maxZIndexItems.map(item => items.findIndex(i => i.id === item.id)));
  
        return selectedItemIndex > maxZIndexItemIndex;
      } else {
        return false; // Selected item has a lower zIndex
      }
    }
    return false;
  }, [selectedItem, collidedItems, items]);
  
  const isSelectedItemOnBottom = useMemo(() => {
    if (selectedItem && collidedItems.length > 0) {
      // Find the minimum zIndex among the collidedItems
      const minZIndex = Math.min(...collidedItems.map(item => item.zIndex));
  
      if (selectedItem.zIndex < minZIndex) {
        return true; // Selected item has a lower zIndex
      } else if (selectedItem.zIndex === minZIndex) {
        // If zIndexes are equal, compare their index positions in the items array
        const selectedItemIndex = items.findIndex(item => item.id === selectedItem.id);
        const minZIndexItems = collidedItems.filter(item => item.zIndex === minZIndex);
        const minZIndexItemIndex = Math.min(...minZIndexItems.map(item => items.findIndex(i => i.id === item.id)));
  
        return selectedItemIndex < minZIndexItemIndex;
      } else {
        return false; // Selected item has a higher zIndex
      }
    }
    return false;
  }, [selectedItem, collidedItems, items]);
  
  


  

  const menuItems = useMemo(() => {

    return [
      { text: "Cut", func: () => cut(selectedItem), disabled: !selectedItem, icon: (<CutShortcut />) },
      { text: "Copy", func: () => copy(selectedItem), disabled: !selectedItem, icon: (<CopyShortcut />) },
      { text: "Paste", func: () => paste(), disabled: copyItems.length === 0, icon: (<PasteShortcut />) },
      { text: "Delete", func: () => handleDelete(), disabled: !(selectedItem && selectedItemIndexes.length > 0), icon: (<DeleteShortcut />) },
      { text: "Divider" },
      { text: "Bring to Front", func: () => zIndexIncrase(), disabled: (isSelectedItemOnTop || collidedItems.length <= 0), icon: (<ZIndexIncreaseShortcut />) },
      { text: "Send to Back", func: () => zIndexDecrease(), disabled: (isSelectedItemOnBottom || collidedItems.length <= 0), icon: (<ZIndexDecreaseShortcut />) },
      { text: "Divider" },
      { text: "Group", func: () => handleGroup(), disabled: selectedItemIndexes?.length < 2, icon: (<GroupShortcut />) },
      { text: "Ungroup", func: () => handleUngroup(), disabled: groupedItems.length === 0  || selectedItemIndexes.length < 2, icon: (<UngroupShortcut />) },
      { text: "Divider" },
      { text: "Flip Horizontal", func: () => handleHorizontalChange(), disabled: !(selectedItem && selectedItemIndexes.length > 0), icon: (<VFlip />) },
      { text: "Flip Vertical", func: () => handleVerticalChange(), disabled: !(selectedItem && selectedItemIndexes.length > 0), icon: (<HFlip />) },
    ]


  }, [selectedItemIndexes, selectedItem, transform]);


  return (
    <div
      className={styles.rightMenu}
      style={{
        position: 'absolute',
        top: position.y,
        left: position.x + 30,
        display: visible ? 'block' : 'none',

      }}
      onClick={onClose}
    >
      <Menu
        theme='light'
        style={{
          width: 200,
          //backgroundColor:'#FFF'
        }}
      >
        {menuItems.map((item, index) => (
          item.text === 'Divider' ? (
            <Menu.Divider
              key={`divider-${index}`}
              style={{
                backgroundColor: 'gray',
              }}
            />
          ) : (
            <Menu.Item
              key={(index + 1).toString()}
              disabled={item.disabled}
              onMouseDown={() => {
                item.func();
                onClose();
              }}
              style={{
                padding: '3px 15px',  // Adjust padding to reduce spacing
                margin: '0.05px',          // Remove any additional margin
                lineHeight: '2',    // Adjust line-height to make items closer
              }}
            >
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 15,
                //color:'#3E3E3E'
              }}>
                {item.text}
                {item.icon}
              </div>
            </Menu.Item>
          )
        ))}

      </Menu>
    </div>
  );
}

export default RightClickMenu
