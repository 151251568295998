import React, { useEffect, useMemo, useState,useRef } from 'react'
import { Card, Flex, Avatar, Tooltip, Popconfirm, Input, Image } from 'antd'
import { ThreeDotIcon } from '../../common/icons/icons';
import styles from './projectcard.module.css'
import IconButton from '../../common/buttons/iconButton'
import { useProjectContext } from '../../../hooks/contextHooks/useProjectContext';
import { useDebounce } from '../../../hooks/asyncHooks/useDebounce';
import { useProjectAPI } from '../../../hooks/apiHooks/projectHooks/useProjectAPI';
import moment from 'moment';
import ScreenshotModal from '../../modals/screenshotModal';

export default function ProjectCard({ projectId, img, title, description, item, sideImages = [], setSideImages }) {
    const { updateProject, deleteProject, createProject } = useProjectAPI();
    const { pDispatch } = useProjectContext();
    const [archived, setArchived] = useState(item.is_archived);
    const [rename, setRename] = useState(false);
    const [clickCount, setClickCount] = useState(0);
    const timeoutRef = useRef(null);


    let itemsArchived = [
        {
            tag: <span>Open in New Tab</span>,
            popconfirm: {
                placement: 'bottom',
                title: 'Warning',
                description: 'Are you sure you want to open this project in a new tab?'
            },
            action: () => window.open('/project/' + item._id)
        },
        {
            tag: <span>Duplicate</span>,
            popconfirm: {
                placement: 'bottom',
                title: 'Warning',
                description: 'Are you sure you want to create a duplicate of this project?'
            },
            action: () => handleCreateProject()
        },
        {
            tag: <span>Rename</span>,
            popconfirm: null,
            action: () => setRename(true)
        },
        {
            tag: <span>Archive</span>,
            popconfirm: {
                placement: 'bottom',
                title: 'Warning',
                description: 'Are you sure you want to archive this project?'
            },
            action: () => handleDropdown(item._id, { is_archived: true })
        },
        {
            tag: <span>Delete</span>,
            popconfirm: {
                placement: 'bottom',
                title: 'Warning',
                description: 'Are you sure you want to delete this project?'
            },
            action: () => handleDeleteProject(item._id)
        }
    ];

    let itemsUnarchived = [
        {
            tag: <span>Restore</span>,
            popconfirm: {
                placement: 'bottom',
                title: 'Warning',
                description: 'Are you sure you want to restore this project?'
            },
            action: () => handleDropdown(item._id, { is_archived: false })
        },
        {
            tag: <span>Delete</span>,
            popconfirm: {
                placement: 'bottom',
                title: 'Warning',
                description: 'Are you sure you want to delete this project forever?'
            },
            action: () => handleDeleteProject(item._id)
        }
    ];




    const dropdownItems = useMemo(() => {
        if (!archived) {
            return itemsArchived;
        }
        else {
            return itemsUnarchived
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [archived, item])

    const handleDropdown = async (id, obj) => {
        await updateProject(id, obj);
        setArchived(obj.is_archived)
        setToggle(false);
    }

    const handleCreateProject = async () => {
        const result = await createProject({
            user_id: item.user_id,
            title: item.title + "(copy)",
            room_type: item.room_type,
            is_archived: item.is_archived,
            room_shape: item.room_shape,
            room_points: item.room_points,
            room_items:item.room_items,
            room_floors:item.room_floors
        })
        pDispatch({ type: 'TRIGGER' })
        let projectId = result.data._id;
        window.location.href = '/project/' + projectId;
    }

    const handleDeleteProject = async (id) => {
        await deleteProject(id);
        setToggle(false);
    }

    const handleRenameSave = useDebounce(async (value) => {
        await updateProject(item._id, { title: value });
        setRename(false);
        setToggle(false);
    }, 1000);

    const handleChange = (event) => {
        const { value } = event.target;
        handleRenameSave(value);
    };

    const handleProjectClick = (e) => {
        if (e.target.matches('h3')) {
            setClickCount(prev => prev + 1);

            if (clickCount === 1) {
                // Double-click detected
                clearTimeout(timeoutRef.current);
                setRename(true);
                setClickCount(0); // Reset click count
            } else {
                // Set timeout for single click
                timeoutRef.current = setTimeout(() => {
                    window.location.href = "/project/" + item._id;
                    setClickCount(0); // Reset click count
                }, 400);
            }
        } else {
            window.location.href = "/project/" + item._id;
        }
    };

    const { Meta } = Card;
    const [toggle, setToggle] = useState("");

    useEffect(() => {
        if (clickCount === 2) {
            setRename(true);
            setClickCount(0);
        }
    }, [clickCount]);

    const memoizedSideImages = useMemo(() => {
        if (sideImages !== undefined) {
            return sideImages.filter((item) => item.project_id === projectId);
        }
        else {
            return [{ src: img }]
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId, sideImages]);

    return (
        <Card
            size="default"
            bordered={true}
            hoverable
            style={{ width: 350, backgroundColor: 'rgba(200, 200, 200, 0.15)' }}
            cover={
                <div style={{ position: 'relative' }}>
                    {<Image preview={false} alt="example" src={memoizedSideImages && memoizedSideImages.length > 0 ? memoizedSideImages[0].src : img} style={{ width: '100%', height: '200px', objectFit: 'cover' }} onClick={(e) => handleProjectClick(e)} />}
                    <div style={{ position: 'absolute', top: 0, right: 0, padding: '10px' }}>
                        <IconButton className={styles.cardButton} clickFunction={() => setToggle(!toggle)}>
                            <ThreeDotIcon />
                        </IconButton>
                        {toggle && (
                            <div className={styles.dropdownContent}>
                                {dropdownItems.map((item, index) => (
                                    (item.popconfirm ? (
                                        <Popconfirm
                                            placement={item.popconfirm.placement}
                                            title={item.popconfirm.title}
                                            key={index}
                                            description={item.popconfirm.description}
                                            okText="Yes"
                                            cancelText="No"
                                            onConfirm={() => item.action(item._id)}
                                        >
                                            <div className={styles.dropdownTag}>
                                                {item.tag}
                                            </div>
                                        </Popconfirm>
                                    ) : (
                                        <div
                                            key={index}
                                            className={styles.dropdownTag}
                                            onClick={() => item.action(item._id)}
                                        >
                                            {item.tag}
                                        </div>
                                    ))
                                ))}

                            </div>
                        )}
                    </div>
                </div>
            }

        >
            <Flex justify='space-between' align='center'>
                {!rename && (
                    <>
                        <div onClick={(e) => handleProjectClick(e)}>
                            <h3 onDoubleClick={() => setClickCount(2)}  style={{ gap: '0.5px', marginTop: '2px', cursor: 'pointer', width: '15em' }}>{title}</h3>
                            <Meta description={description} style={{ color: 'black', cursor: 'pointer' }} />
                        </div>
                        <div style={{
                            display: 'none'
                        }}>
                            <ScreenshotModal screenshots={memoizedSideImages} setScreenshots={setSideImages} />
                        </div>
                        <Avatar.Group justify="middle" style={{ gap: 2 }} maxCount={1}>
                            {memoizedSideImages.map((item, index) => (
                                <Tooltip title={"SS Taken: " + moment(item.createdAt).fromNow()} placement="top" key={index}>
                                    <Avatar
                                        style={{ backgroundColor: '#12c062', borderColor: '#c4c4c4' }}
                                        src={item.src}
                                        onClick={() => {
                                            // This triggers the preview of the image
                                            document.getElementById(`hidden-img-${item._id.toString()}`).click();
                                        }}
                                    >
                                    </Avatar>
                                </Tooltip>
                            ))}
                            {/*memoizedSideImages.length === 0 && (
                                <Avatar style={{ backgroundColor: '#12c062' }}>
                                    {user.username.charAt(0)}
                                </Avatar>
                            )*/}
                        </Avatar.Group>


                    </>

                )}
                {rename && (
                    <Input
                        placeholder='Enter name'
                        onChange={(e) => handleChange(e)}
                        defaultValue={title}
                        style={{
                            marginRight: 10,
                            background: '#C8C8C833',
                            padding: 10
                        }}
                    />
                )}
            </Flex>
        </Card>
    )
}
