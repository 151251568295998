import { useState } from "react"
import axios from "axios";
import { useProjectContext } from "../../contextHooks/useProjectContext";
import { useAuthContext } from "../../contextHooks/useAuthContext";
import config from '../../../config/config.json';

export const useProjectAPI = () => {
    const {user} = useAuthContext();
    const {pDispatch,trigger} = useProjectContext();
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");


    /**
     * Creates a new Project
     * @param {*Object} obj The Object
     */
    const createProject = async (obj) => {
        try {
            const result = await axios.post(config.REACT_APP_API_MAIN_ROUTE + "/projects",obj, {
                headers : {
                    Authorization : "Bearer " + user.token
                }
            });
            pDispatch({type:'TRIGGER'});
            return result.data;
        }
        catch (e) {
            setError(true);
            setMessage("An error occured")
        }
    }


    /**
     *
     * @param {*string} projectId The Parameter id
     * @param {*Object} obj The Request Body
     */
    const updateProject = async (projectId, obj) => {
        try {
            await axios.put(config.REACT_APP_API_MAIN_ROUTE + '/projects/' + projectId,
                obj,
                {
                    headers: {
                        Authorization: "Bearer " + user.token
                    }
                }
            )
            pDispatch({ type: 'TRIGGER'})
        }
        catch (e) {
            setError(true);
            setMessage("An error occured")
        }
    }


    /**
     *
     * @param {*string} projectId The Project Id
     */
    const deleteProject = async (projectId) => {
        try {
            const result = await axios.delete(config.REACT_APP_API_MAIN_ROUTE + '/projects/' + projectId,
                {
                    headers: {
                        Authorization: "Bearer " + user.token
                    }
                }
            )
            if(result){
                pDispatch({ type: 'TRIGGER' });
            }

        }
        catch (e) {
            setError(true);
            setMessage("An error occured, Could not delete the Project")
        }
    }


    return {error,message,updateProject, deleteProject, createProject};
}