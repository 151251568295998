import axios from "axios";
import config from '../../config/config.json';

export const useFetchCampaignData = () => {


    const fetchCampaignData = async () => {
        try{
            const url = localStorage.getItem('entryUrl');
            if(url !== undefined && url){
                await axios.post(config.REACT_APP_API_MAIN_ROUTE + '/campaign/retrieve',{
                    entryUrl : url
                });
                return;
            }
        } catch(e){
            console.log(e);
            return;
        }
    };


    return {fetchCampaignData};
};