import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import { createContext, useReducer, useEffect, useState, useCallback } from 'react';
import config from '../config/config.json';

export const AuthContext = createContext();

export const authReducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN':
            return { ...state, user: action.payload }
        case 'LOGOUT':
            return { ...state, user: null , profilePic:""}
        case 'GET_PROFILE_PIC':
            return { ...state, profilePic:action.payload}
        default:
            return state;
    }
}

export const AuthContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, {
        user: null,
        profilePic:""
    })

    const [loaded, setLoaded] = useState(false);
    const [storedUser, setStoredUser] = useState(JSON.parse(localStorage.getItem('user')));

    const fetchToken = useCallback(async () => {
        try {
            const res = await axios.post(config.REACT_APP_API_MAIN_ROUTE + '/auth/token/refresh', storedUser);
            let userWithRefreshedToken = { ...storedUser, token: res.data.token };
            dispatch({ type: 'LOGIN', payload: userWithRefreshedToken });
        } catch (error) {
            // Handle errors, e.g., redirect to login page if refresh token is invalid
            // Or dispatch a LOGOUT action
            dispatch({ type: 'LOGOUT' });
        }
    }, [storedUser]);

    useEffect(() => {

        const isValidSession = () => {
            const jwtToken = storedUser?.token;


            if (jwtToken) {
                try {
                    const decodedToken = jwtDecode(jwtToken);
                    // Check if the token is expired or about to expire (e.g., within the next 5 minutes)
                    const isTokenExpired = decodedToken.exp < (Date.now() / 1000);

                    return !isTokenExpired;
                } catch (error) {
                    // Handle decoding errors
                    console.error('Error decoding JWT token:', error);
                }
            }

            return false;
        };

        const refreshSession = async () => {
            if (isValidSession()) {
                dispatch({ type: 'LOGIN', payload: storedUser });
            } else {
                // Refresh Token
                await fetchToken();
            }
            setLoaded(true);
        };

        if (storedUser) {
            refreshSession();
            const intervalId = setInterval(refreshSession, 5 * 60 * 60 * 1000); // Every 3 hours

            // Cleanup interval on component unmount
            return () => clearInterval(intervalId);
        }


    }, [fetchToken, storedUser]);

    return (
        <AuthContext.Provider value={{ ...state, dispatch, loaded }}>
            {children}
        </AuthContext.Provider>
    )
}