import React from "react";

export default function Col({children}){
    return(
        <div style={{
            display: 'flex', 
            flexDirection: 'row'
        }}>
            {children}
        </div>
    )
}