import { Avatar, Image, Space, message } from 'antd'
import axios from 'axios';
import React, { useEffect  } from 'react';
import config from '../../../config/config.json';
import { useAuthContext } from '../../../hooks/contextHooks/useAuthContext';
import {
    DeleteOutlined,
    RotateLeftOutlined,
    RotateRightOutlined,
    SwapOutlined,
    ZoomInOutlined,
    ZoomOutOutlined,
} from '@ant-design/icons';
import styles from './profileAvatar.module.css';

function ProfileAvatar({ letter, size }) {
    const { user, profilePic, dispatch } = useAuthContext();
    
    const handleDeleteProfilePic = async () => {
        try {
            await axios.delete(config.REACT_APP_API_MAIN_ROUTE + '/profilepics/' + user.userIdMongo, {
                headers: {
                    Authorization: "Bearer " + user.token
                }
            });

            message.success("Profile Picture Deleted successfully");
            dispatch({type:'GET_PROFILE_PIC',payload:""})
        }
        catch (e) {
            console.error(e);
            message.error('Failed to delete the Profile Picture.');
        }
    }


    if (profilePic !== "") {
        return (
            <Image.PreviewGroup preview={{
                toolbarRender: (
                    _,
                    {
                        transform: { scale },
                        actions: {
                            onFlipY,
                            onFlipX,
                            onRotateLeft,
                            onRotateRight,
                            onZoomOut,
                            onZoomIn,
                        },
                    },
                ) => (
                    <Space size={20} className={styles.toolbarWrapper}>
                        <DeleteOutlined onClick={handleDeleteProfilePic} />
                        <SwapOutlined rotate={90} onClick={onFlipY} />
                        <SwapOutlined onClick={onFlipX} />
                        <RotateLeftOutlined onClick={onRotateLeft} />
                        <RotateRightOutlined onClick={onRotateRight} />
                        <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                        <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                    </Space>
                ),
            }}>
                <Avatar size={size} style={{ backgroundColor: '#1677ff', cursor: 'pointer' }} src={profilePic} preview={"true"} onClick={() => {
                    // This triggers the preview of the image
                    document.getElementById(`profile-img-${user.userIdMongo.toString()}`).click();
                }}></Avatar>
                {<div style={{ display: 'none' }}><Image src={profilePic} id={`profile-img-${user.userIdMongo}`} /></div>}
            </Image.PreviewGroup>

        )
    }
    else {
        return (
            <Avatar size={size} style={{ backgroundColor: '#1677ff' }}>{letter}</Avatar>
        )
    }

}

export default ProfileAvatar;