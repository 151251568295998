import React, { Fragment, useEffect, useState } from 'react'
import { Divider, Flex, Image, Modal, Checkbox, Typography  } from 'antd';
import Section from '../../common/section/section';
import styles from './helperModal.module.css';
import image from '../../../assets/helper-img/helper.jpg'
import { useAuthContext } from '../../../hooks/contextHooks/useAuthContext';
import { useUserAPI } from '../../../hooks/apiHooks/userHooks/useUserAPI';
import { videos } from '../../../data/videocards';

function HelperModal({helperOpen, onClose}) {
    
    const {user,dispatch} = useAuthContext();
    const [checked,setChecked] = useState(true)
    const {updateUser} = useUserAPI();

    const onChange = async (e) => {
        setChecked(e.target.checked);
        let updatedUser;
        if(e.target.checked === false){
           updatedUser = {...user,helpEnabled:true};
        }
        else{
            updatedUser = {...user,helpEnabled:false};
        }
        await updateUser(user.userIdMongo,updatedUser)
        dispatch({type:'LOGIN', payload:updatedUser});
        localStorage.setItem('user', JSON.stringify(updatedUser));
    };


    const handleClose = async () => {
        if(checked && user.helpEnabled){
            const updatedUser = {...user,helpEnabled:false};
            await updateUser(user.userIdMongo,updatedUser);
            dispatch({type:'LOGIN', payload:updatedUser});
            localStorage.setItem('user', JSON.stringify(updatedUser));
        }
        onClose();
    }

    

    return (
        <Modal 
            open={helperOpen} onCancel={handleClose} 
            cancelText='Skip' onOk={onClose} width={700}
            footer={
                <Flex justify='flex-start'>
                    <Checkbox style={{alignSelf:'left'}} checked={checked} onChange={onChange}>Don't show this again </Checkbox>
                </Flex>
                
            }
        >
            <div style={{
                padding: '30px',
                gap: '10px',
                alignItems: 'flex-start',
            }}>
                <span className={styles.modalLabel}> Welcome to Decobot </span>
                <Divider style={{ margin: 10 }} />
                <span>
                    Our user-friendly platform empowers you to effortlessly design your dream space using drag-and-drop functions.
                    Customize every aspect, from wall structures to furniture sizes and colors, and add intricate design details 
                    seamlessly.
                </span>
              {/*<Flex justify='center' style={{
                    marginTop:20
                }}><Image src={image} style={{ borderRadius:10}} height={400} /></Flex>*/}
                <Section style={{marginTop:10}}>
                    <Typography variant='h5'>{videos[0].title}</Typography>
                    <Divider style={{ marginBottom: 10, marginTop: 10 }} />
                    <iframe
                        className={styles.videoSection}
                        src={videos[0].video}
                        title="Video player"
                        controls // Adding controls so the user can play manually
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                        height={400}
                    ></iframe>
                </Section>
            </div>
        </Modal>
    )
}

export default HelperModal
