import { Flex,List } from 'antd';
import React, { useEffect } from 'react';


export default function Menu({ items, pickFunction, picked }) {
    //Handlers
    const handleSelected = (num) => {
        pickFunction(num)
    }

    return (
        <List
            bordered
            dataSource={items}
            renderItem={(item, index) => (
                <List.Item style={{
                    minWidth: 185,
                    background: picked === index ? '#C8C8C833' : "",
                    transition: "0.3s ease",
                    cursor: 'pointer'
                }}
                    onClick={() => handleSelected(index)}
                >
                    <Flex
                        justify='space-between'
                        gap={14}
                        align='center'
                        key={index}
                    >
                        {item.icon}
                        <span key={index}>{item.name}</span>
                    </Flex>
                </List.Item>
            )}
        />
    )
}
