import { Divider, Flex } from 'antd'
import React from 'react'
import Panel from '../../common/panel'
import { BringToBackIcon, BringToFont, GroupIcons, HIcon, KeyMoveIcon, KeyZoomIcon, MouseMoveIcon, MouseZoomIcon, SelectMultipleItemsIcon, UngroupIcon, VIcon } from '../../common/icons/icons'
import { HFlip, VFlip } from '../../common/icons/shortcutIcons/shortcutIcons'
import { CloseOutlined } from '@ant-design/icons'


function ShortcutPanel({ handleVisible }) {
    return (
        <Flex vertical style={{
            alignSelf: 'flex-end',
            padding: '14px',
        }}>
            {<Flex justify='space-between'>
                <span style={{
                    fontSize: 17,
                    fontWeight: 500
                }}>Shortcuts</span>
                <CloseOutlined onClick={() => handleVisible(false)} height={15} width={15} style={{
                    marginRight: 5.5
                }} />
            </Flex>}
            {<Divider style={{ marginTop: 10, marginBottom: 10 }} />}
            <Flex vertical gap={10} style={{
                height: '365px',
                width: '218px',
                overflowY: 'auto',
                scrollbarWidth: 'none'
            }}>
                <Flex justify='space-between' align='center'>
                    <span style={{ fontSize: 15 }}> Moving </span>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 5
                    }}>
                        <MouseMoveIcon />
                        <KeyMoveIcon />
                    </div>
                </Flex>
                <Flex justify='space-between' align='center'>
                    <span style={{ fontSize: 15 }}> Zoom </span>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 5
                    }}>
                        <MouseZoomIcon />
                        <KeyZoomIcon />
                    </div>
                </Flex>
                <Divider style={{ marginTop: 0, marginBottom: 0 }} />
                <Flex justify='space-between' align='center'>
                    <span style={{ fontSize: 15 }}> Flip Vertical </span>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 5
                    }}>
                        <VIcon />
                    </div>
                </Flex>
                <Flex justify='space-between' align='center'>
                    <span style={{ fontSize: 15 }}> Flip Horizontal </span>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 5
                    }}>
                        <HIcon />
                    </div>
                </Flex>
                <Divider style={{ marginTop: 0, marginBottom: 0 }} />
                <Flex justify='space-between' align='center'>
                    <span style={{ fontSize: 15 }}> Bring to Front </span>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 5
                    }}>
                        <BringToFont />
                    </div>
                </Flex>
                <Flex justify='space-between' align='center'>
                    <span style={{ fontSize: 15 }}> Bring to Back </span>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 5
                    }}>
                        <BringToBackIcon />
                    </div>
                </Flex>
                <Divider style={{ marginTop: 0, marginBottom: 0 }} />
                <Flex justify='space-between' align='center'>
                    <span style={{ fontSize: 15 }}> Select Multiple Items </span>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 5
                    }}>
                        <SelectMultipleItemsIcon />
                    </div>
                </Flex>
                <Flex justify='space-between' align='center'>
                    <span style={{ fontSize: 15 }}> Group </span>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 5
                    }}>
                        <GroupIcons />
                    </div>
                </Flex>
                <Flex justify='space-between' align='center'>
                    <span style={{ fontSize: 15 }}> Ungroup </span>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 5
                    }}>
                        <UngroupIcon />
                    </div>
                </Flex>
            </Flex>
        </Flex>
    )
}

export default ShortcutPanel
