import React from 'react'
import { Card, Flex } from 'antd'
import styles from './videocard.module.css';


export default function Videocard({ title, video }) {
    const { Meta } = Card;



    return (
        <Card
            size="default"
            bordered={true}
            hoverable
            style={{ width: 350, backgroundColor: 'rgba(200, 200, 200, 0.15)' }}
            cover={
                <iframe
                    className={styles.videoSection}
                    src={video}
                    title="YouTube video player" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowFullScreen 
                    >
                    
                </iframe>
            }
        >
            <Flex justify='space-between' align='center'>
                <div onClick={() => console.log("Hello")}>
                    <h4 style={{ gap: '0.5px', marginTop: '2px', cursor: 'pointer' }}>{title}</h4>
                    <Meta style={{ color: 'black', cursor: 'pointer' }} />
                </div>
            </Flex>

        </Card>
    )
}
