import React, { Fragment, useEffect } from 'react'
import Section from '../../section/section';
import Panel from '../../panel/panel';
import panelstyle from '../../panel/panel.module.css'
import { Divider, Row, Col, Flex } from 'antd';
import { roomDialogMenu } from '../../../../api/data/roomDialogData';
import { useProjectAPI } from '../../../../hooks/apiHooks/projectHooks/useProjectAPI';
import roomtypestyle from './roomtypedialog.module.css';

const boxStyle = {
    minHeight: '6vh',
    height: '11vh',
    minWidth: '5.20vw',
    maxWidth: '9.20vw',
    flexShrink: 0,
    background: '#C8C8C833',
    borderRadius: '6px',
}


const boxStyleHover = {
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
    borderRadius: 10
}

export default function RoomTypeDialog({ projectId, select }) {
    // Hooks
    const [hover, setHover] = React.useState("");
    const { updateProject } = useProjectAPI();

    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
    const [colsPerRow, setColsPerRow] = React.useState(5);

    useEffect(() => {
        // Function to handle window resize and update screenWidth state
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (screenWidth < 900) {
            setColsPerRow(23);
        }
        else if (screenWidth < 1000) {
            setColsPerRow(11);
        }
        else {
            setColsPerRow(5);
        }
    }, [screenWidth]);

    //Handlers
    const handleRoomTypeSave = async (name) => {
        select(name);
        await updateProject(projectId, { room_type: name });
    }


    return (
        <div className={roomtypestyle.modal}>
            <Panel className={panelstyle.itemPanel}>
                <Section style={{
                    padding: '30px',
                    maxWidth: '36em',
                    gap: '10px',
                    maxHeight: '40vh',
                    alignItems: 'flex-start',
                    overflowY: 'auto',
                    overflowX: 'hidden'
                }}>
                    <span> Select a Room Type </span>
                    <Divider orientation='left' style={{ marginTop: 5 }} />
                    <Row gutter={[12, 16]}>
                        {roomDialogMenu.map((option, index) => (
                            <Fragment key={index}>
                                <Col
                                    key={index}
                                    span={colsPerRow + 1}
                                    onMouseEnter={() => setHover(index.toString())}
                                    onMouseLeave={() => setHover("")}
                                    onClick={() => handleRoomTypeSave(option.name)}
                                >
                                    <Flex align='center' justify='center' vertical style={{ ...boxStyle, ...(hover === index.toString() ? boxStyleHover : '') }}>
                                        {option.img}
                                    </Flex>
                                    <div style={{ marginTop: '0.4em' }}>
                                        <span>{option.name}</span>
                                    </div>

                                </Col>
                            </Fragment>
                        ))}
                    </Row>
                </Section>
            </Panel>
        </div>
    )
}
