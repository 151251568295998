import { createContext, useReducer } from 'react';

export const ProjectContext = createContext();

export const projectReducer = (state,action) => {
    switch(action.type){
        case 'TRIGGER':
            return { ...state, trigger: state.trigger + 1 }
        default:
            return state;
    }
}

export const ProjectContextProvider = ({children}) => {
    const [state,pDispatch] = useReducer(projectReducer, {
        trigger: 0
    })
    
    return (
        <ProjectContext.Provider value={{...state,pDispatch}}>
            {children}
        </ProjectContext.Provider>
    )
}