
import blueCarpet1 from '../../assets/Carpets/Carpet_001/Carpet_001_Blue.jpg';
import redCarpet1 from '../../assets/Carpets/Carpet_001/Carpet_001_Red.jpg';
import whiteCarpet1 from '../../assets/Carpets/Carpet_001/Carpet_001_White.jpg';
import purpleCarpet1 from '../../assets/Carpets/Carpet_001/Carpet_001_purple.jpg';
import yellowCarpet1 from '../../assets/Carpets/Carpet_001/Carpet_001_Yellow.jpg';

import blueCarpet2 from '../../assets/Carpets/Carpet_002/Carpet_002_Blue.jpg';
import redCarpet2 from '../../assets/Carpets/Carpet_002/Carpet_002_REd.jpg';
import whiteCarpet2 from '../../assets/Carpets/Carpet_002/Carpet_002_White.jpg';
import yellowCarpet2 from '../../assets/Carpets/Carpet_002/Carpet_002_yellow.jpg';

import blueCarpet3 from '../../assets/Carpets/Carpet_003/Carpet_003_blue.jpg';
import redCarpet3 from '../../assets/Carpets/Carpet_003/Carpet_003_REd.jpg';
import whiteCarpet3 from '../../assets/Carpets/Carpet_003/Carpet_003_white.jpg';
import yellowCarpet3 from '../../assets/Carpets/Carpet_003/Carpet_003_yellow.jpg';
import greenCarpet3 from '../../assets/Carpets/Carpet_003/Carpet_003_green.jpg';

import blueCarpet4 from '../../assets/Carpets/Carpet_004/Carpet_004_blue.jpg';
import redCarpet4 from '../../assets/Carpets/Carpet_004/Carpet_004_red.jpg';
import whiteCarpet4 from '../../assets/Carpets/Carpet_004/Carpet_004_white.jpg';
import yellowCarpet4 from '../../assets/Carpets/Carpet_004/Carpet_004_yellow.jpg';
import purpleCarpet4 from '../../assets/Carpets/Carpet_004/Carpet_004_purple.jpg';

import blueCarpet5 from '../../assets/Carpets/Carpet_005/Carpet_005_blue.jpg';
import redCarpet5 from '../../assets/Carpets/Carpet_005/Carpet_005_red.jpg';
import whiteCarpet5 from '../../assets/Carpets/Carpet_005/Carpet_005_white.jpg';
import yellowCarpet5 from '../../assets/Carpets/Carpet_005/Carpet_005_yellow.jpg';

import blueCarpet6 from '../../assets/Carpets/Carpet_006/Carpet_006_blue.jpg';
import redCarpet6 from '../../assets/Carpets/Carpet_006/Carpet_006_red.jpg';
import whiteCarpet6 from '../../assets/Carpets/Carpet_006/Carpet_006_white.jpg';
import yellowCarpet6 from '../../assets/Carpets/Carpet_006/Carpet_006_yellow.jpg';
import greenCarpet6 from '../../assets/Carpets/Carpet_006/Carpet_006_green.jpg';

import blueCarpet7 from '../../assets/Carpets/Carpet_007/Carpet_007_blue.jpg';
import redCarpet7 from '../../assets/Carpets/Carpet_007/Carpet_007_red.jpg';
import whiteCarpet7 from '../../assets/Carpets/Carpet_007/Carpet_007_white.jpg';
import yellowCarpet7 from '../../assets/Carpets/Carpet_007/Carpet_007_yellow.jpg';
import greenCarpet7 from '../../assets/Carpets/Carpet_007/Carpet_007_green.jpg';
import purpleCarpet7 from '../../assets/Carpets/Carpet_007/Carpet_007_purple.jpg';

import blueCarpet9 from '../../assets/Carpets/Carpet_009/Carpet_009_blue.jpg';
import redCarpet9 from '../../assets/Carpets/Carpet_009/Carpet_009_red.jpg';
import blackCarpet9 from '../../assets/Carpets/Carpet_009/Carpet_009_black.jpg';
import yellowCarpet9 from '../../assets/Carpets/Carpet_009/Carpet_009_yellow.jpg';
import greenCarpet9 from '../../assets/Carpets/Carpet_009/Carpet_009_green.jpg';

import blueCarpet11 from '../../assets/Carpets/Carpet_011/Carpet_011_blue.jpg';
import purpleCarpet11 from '../../assets/Carpets/Carpet_011/Carpet_011_purple.jpg';
import mixCarpet11 from '../../assets/Carpets/Carpet_011/Carpet_011_mix.jpg';
import greenCarpet11 from '../../assets/Carpets/Carpet_011/Carpet_011_green.jpg';

import blueCarpet12 from '../../assets/Carpets/Carpet_012/Carpet_012_blue.png';
import whiteCarpet12 from '../../assets/Carpets/Carpet_012/Carpet_012_white.png';
import yellowCarpet12 from '../../assets/Carpets/Carpet_012/Carpet_012_yellow.png';
import greenCarpet12 from '../../assets/Carpets/Carpet_012/Carpet_012_green.png';

import blueCarpet14 from '../../assets/Carpets/Carpet_014/Carpet_014_blue.png';
import redCarpet14 from '../../assets/Carpets/Carpet_014/Carpet_014_red.jpg';
import orangeCarpet14 from '../../assets/Carpets/Carpet_014/Carpet_014_orange.png';
import turquoiseCarpet14 from '../../assets/Carpets/Carpet_014/Carpet_014_Turquoise.png';

import purpleCarpet15 from '../../assets/Carpets/Carpet_015/Carpet_015_purple.png';
import redCarpet15 from '../../assets/Carpets/Carpet_015/Carpet_015_red.png';
import whiteCarpet15 from '../../assets/Carpets/Carpet_015/Carpet_015_white.png';
import greenCarpet15 from '../../assets/Carpets/Carpet_015/Carpet_015_green.jpg';
import yellowCarpet15 from '../../assets/Carpets/Carpet_015/Carpet_015_yellow.png';

import blueCarpet16 from '../../assets/Carpets/Carpet_016/Carpet_016_blue.png';
import redCarpet16 from '../../assets/Carpets/Carpet_016/Carpet_016_red.png';
import whiteCarpet16 from '../../assets/Carpets/Carpet_016/Carpet_016_white.jpg';
import yellowCarpet16 from '../../assets/Carpets/Carpet_016/Carpet_016_yellow.png';
import greenCarpet16 from '../../assets/Carpets/Carpet_016/Carpet_016_green.png';

import blueCarpet17 from '../../assets/Carpets/Carpet_017/Carpet_017_blue.png';
import redCarpet17 from '../../assets/Carpets/Carpet_017/Carpet_017_red.png';
import whiteCarpet17 from '../../assets/Carpets/Carpet_017/Carpet_017_white.jpg';
import yellowCarpet17 from '../../assets/Carpets/Carpet_017/Carpet_017_yellow.png';
import purpleCarpet17 from '../../assets/Carpets/Carpet_017/Carpet_017_purple.png';


import blueCarpet18 from '../../assets/Carpets/Carpet_018/Carpet_018_blue.png';
import redCarpet18 from '../../assets/Carpets/Carpet_018/Carpet_018_red.png';
import whiteCarpet18 from '../../assets/Carpets/Carpet_018/Carpet_018_white.jpg';
import yellowCarpet18 from '../../assets/Carpets/Carpet_018/Carpet_018_yellow.png';
import purpleCarpet18 from '../../assets/Carpets/Carpet_018/Carpet_018_purple.png';

const carpet1Variations = [
    blueCarpet1,
    redCarpet1,
    whiteCarpet1,
    purpleCarpet1,
    yellowCarpet1
];

const carpet2Variations = [
    blueCarpet2,
    redCarpet2,
    whiteCarpet2,
    yellowCarpet2
];

const carpet3Variations = [
    blueCarpet3,
    redCarpet3,
    whiteCarpet3,
    yellowCarpet3,
    greenCarpet3
];

const carpet4Variations = [
    blueCarpet4,
    redCarpet4,
    whiteCarpet4,
    yellowCarpet4,
    purpleCarpet4
];

const carpet5Variations = [
    blueCarpet5,
    redCarpet5,
    whiteCarpet5,
    yellowCarpet5,
];

const carpet6Variations = [
    blueCarpet6,
    redCarpet6,
    whiteCarpet6,
    yellowCarpet6,
    greenCarpet6
];

const carpet7Variations = [
    blueCarpet7,
    redCarpet7,
    whiteCarpet7,
    yellowCarpet7,
    greenCarpet7
];

const carpet9Variations = [
    blueCarpet9,
    redCarpet9,
    blackCarpet9,
    yellowCarpet9,
    greenCarpet9
];

const carpet11Variations = [
    blueCarpet11,
    purpleCarpet11,
    mixCarpet11,
    greenCarpet11
];

const carpet12Variations = [
    blueCarpet12,
    whiteCarpet12,
    yellowCarpet12,
    greenCarpet12
];

const carpet14Variations = [
    blueCarpet14,
    redCarpet14,
    orangeCarpet14,
    turquoiseCarpet14
];
const carpet15Variations = [
    purpleCarpet15,
    redCarpet15,
    whiteCarpet15,
    greenCarpet15,
    yellowCarpet15
  ];
  
  const carpet16Variations = [
    blueCarpet16,
    redCarpet16,
    whiteCarpet16,
    yellowCarpet16,
    greenCarpet16
  ];
  
  const carpet17Variations = [
    blueCarpet17,
    redCarpet17,
    whiteCarpet17,
    yellowCarpet17,
    purpleCarpet17
  ];
  
  const carpet18Variations = [
    blueCarpet18,
    redCarpet18,
    whiteCarpet18,
    yellowCarpet18,
    purpleCarpet18
  ];


export const extractNumber = (filePath) => {
    // Use a regular expression to match the pattern
    const match = filePath.match(/_(\d+)_/);
    return match ? match[1] : null;
};

export const getImageArray = (number) => {
    switch (number) {
        case '001':
            return carpet1Variations;
        case '002':
            return carpet2Variations;
        case '003':
            return carpet3Variations;
        case '004':
            return carpet4Variations;
        case '005':
            return carpet5Variations;
        case '006':
            return carpet6Variations;
        case '007':
            return carpet7Variations;
        case '009':
            return carpet9Variations;
        case '011':
            return carpet11Variations;
        case '012':
            return carpet12Variations;
        case '014':
            return carpet14Variations;
        case '015':
            return carpet15Variations;
        case '016':
            return carpet16Variations;
        case '017':
            return carpet17Variations;
        case '018':
            return carpet18Variations;

        default:
            return [];
    }
}