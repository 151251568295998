// DragContext.js
import React, { createContext, useContext, useReducer } from 'react';

export const initialState = {
    draggedItem: null,
};

export const dragReducer = (state, action) => {
    switch (action.type) {
        case 'START_DRAG':
            return {
                ...state,
                draggedItem: action.payload,
            };
        case 'MOVE_DRAG':
            // Handle drag move logic if needed
            return state;
        case 'END_DRAG':
            return {
                ...state,
                draggedItem: null,
            };
        default:
            return state;
    }
};

export const DragContext = createContext();

export const useDragContext = () => {
    return useContext(DragContext);
};

export const DragProvider = ({ children }) => {
    const [state, dispatch] = useReducer(dragReducer, initialState);

    const startDrag = (item) => {
        dispatch({ type: 'START_DRAG', payload: item });
    };

    const moveDrag = () => {
        console.log("Drag being moved ");
        dispatch({ type: 'MOVE_DRAG' });
    };

    const endDrag = () => {
        dispatch({ type: 'END_DRAG' });
    };

    return (
        <DragContext.Provider value={{ ...state, startDrag, moveDrag, endDrag }}>
            {children}
        </DragContext.Provider>
    );
};
