import video1 from '../assets/videos/Key Features Overview.mp4';
import video2 from '../assets/videos/Creating a New Project.mp4';
import video3 from '../assets/videos/Beginners Guide to Drawing a Room.mp4';
import video4 from '../assets/videos/Decorating your house_1.mp4';

export const videos = [
    {id:1,title:"Key Features Overview",video:"https://www.youtube.com/embed/vL1coZwC7Tk?si=ayuD9y_uWnTKap87"},
    {id:2,title:"Creating a New Project",video:"https://www.youtube.com/embed/b-aRgQHyaNU?si=IZKiqQMqt72buDNJ"},
    {id:3,title:"Beginners Guide to Drawing a Room", video:"https://www.youtube.com/embed/W6CA-xSGSUQ?si=cU6iKA7DYFnTe4JU"},
    {id:4,title:"Decorating Your House", video:"https://www.youtube.com/embed/s45U-Z78faE?si=tYDgXTBY7kndh5J3"}
]
