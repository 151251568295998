import { cloneDeep } from "lodash";
import { generateId } from "./helper";
import { useSnapshotContext } from "../hooks/contextHooks/useSnapshotContext";


export default function TopbarFunctions({
    copyItems,
    setCopyItems,
    selectedItem,
    handleObjectDeletion,
    transform,
    points,
    items,
    setItems,
    wallsRef,
    selectedItemIndexes
}){


    const { offsetX, offsetY, scale, dragX, dragY } = transform;   
    const {snapshotDispatch} = useSnapshotContext();


    const cut = (item = selectedItem) => {
        if(selectedItemIndexes.length > 1){
            let arr = [];
            selectedItemIndexes.forEach((id) => {
                arr.push(items[id]);
            });
            setCopyItems(arr);
            handleObjectDeletion();
        }
        else if (selectedItem) {
            setCopyItems([selectedItem]);
            handleObjectDeletion();
        } else {
            console.log("Please choose an item to cut");
        }
    }

    const copy = (item = selectedItem) => {
        if(selectedItemIndexes.length > 1){
            let arr = [];
            selectedItemIndexes.forEach((id) => {
                arr.push(items[id]);
            });
            setCopyItems(arr);
        }
        else if (item) {
            setCopyItems([item]);
            console.log("Item copied");
        }
        else {
            console.log("Please choose an item to copy")
        }
    }


    const paste = () => {
        if (copyItems.length > 0) {
            let pasteArr =  [];
            copyItems.forEach((item,index) => {
                const screenWidth = window.innerWidth;
                const screenHeight = window.innerHeight;
    
                const centerX = (screenWidth / 2);
                const centerY = (screenHeight / 2);
    
                const adjustedX = centerX / scale + offsetX / scale - dragX / scale - item.width / 2;
                const adjustedY = centerY / scale + offsetY / scale - dragY / scale + item.height / 2;
                pasteArr.push({
                    ...item,
                    x: adjustedX + index*100,
                    y: -adjustedY - index*100,
                    height: item.height || 60,
                    width: item.width || 60,
                    rotation: 180,
                    id: generateId()
                })
            })
           

            setItems((prev) => [...prev,...pasteArr]);
            const copiedPoints = cloneDeep(points.current);
            const copiedWalls = cloneDeep(wallsRef.current);

            snapshotDispatch({
                type: 'SNAPSHOT', payload: {
                    items: [...items, ...pasteArr], walls: copiedWalls, points: copiedPoints
                }
            });
        } else {
            console.log("Please choose an item to paste");
        }
    }

    return {
        cut,
        copy,
        paste
    }
}