import React from 'react';

import Panel from '../panel';

// styles
import styles from './toolbar.module.css';

export default function Toolbar({ children, ...props }) {
    return (
        <Panel className={styles.toolbar} {...props}>
            {children}
        </Panel>
    );
}
