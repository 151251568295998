import { useContext } from "react";
import { FloorContext } from "../../contexts/FloorContext";


export const useFloorContext = () => {
    const context = useContext(FloorContext);
    if(!context){
        throw Error('useFloorContext must be used inside an FloorContext Provider ')
    }
    return context;
}