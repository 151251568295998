import React, { useState } from 'react';
import { MinusIcon, PlusIcon } from '../icons/icons';
import IconButton from '../buttons/iconButton/iconButton';
import { Flex, Input } from 'antd';
import { useDebounce } from '../../../hooks/asyncHooks/useDebounce';

export default function Incrementer({ name, data, increment, decrement, adjustValue }) {
    const [textOpen, setTextOpen] = useState(false);

    const handleInput = useDebounce(async (value) => {
        if (value > 0) {
            adjustValue(parseInt(value));
        }
        setTextOpen(false);
    }, 2500);

    if (textOpen) {
        return (
            <Flex justify='space-between' align='center' style={{ marginLeft: 10 }}>
                <span style={{ fontSize: "14px" }}> {name}  </span>
                <Flex align='center' gap={10}>
                    <Input onChange={(e) => handleInput(e.target.value)}></Input>
                </Flex>
            </Flex>
        )
    }
    else {
        return (
            <Flex justify='space-between' align='center' style={{ marginLeft: 10 }} >
                <span style={{ fontSize: "14px" }}> {name}  </span>
                <Flex align='center' gap={10}>
                    <IconButton className={'icon-button'} clickFunction={decrement}>
                        <MinusIcon />
                    </IconButton>
                    <span style={{ fontSize: '17px' }} onDoubleClick={() => {
                        setTextOpen(true);
                    }}> {data} </span>
                    <IconButton className={'icon-button'} clickFunction={increment}>
                        <PlusIcon />
                    </IconButton>
                </Flex>
            </Flex>
        )
    }

}
