import React, { useEffect } from 'react'

const Hubspot = () => {
    useEffect(() => {
      const script = document.createElement('script');
        // Insert the specific HubSpot chat widget script here
        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;
        script.src = 'https://js-eu1.hs-scripts.com/145131555.js'; // Replace with your specific script URL
        document.body.appendChild(script);
        return () => {
          // Clean up the script when the component unmounts
          document.body.removeChild(script);
        };
    }, []);
  
    return null; // No need to render anything
  };

export default Hubspot
