import React, { useEffect, useState } from 'react'
import { Button, Col, Divider, Flex, Image, Row, Typography } from 'antd';
import Panel from '../../common/panel/panel';
import styles from '../../item-panel/item-panel.module.css';
import Section from '../../common/section/section';
import specificstyle from './specificpanel.module.css';
import ProportionSection from '../../panel-sections/proportion-section';
import { ItemIcon } from '../../../api/data/menu';
import { useParams } from 'react-router-dom';
import Renders from '../../common/renders/renders';
import { videos } from '../../../data/videocards';
import { carpet1Variations, extractNumber, getImageArray } from '../../../data/carpetVariations/carpetVariations';
import { cloneDeep } from 'lodash';
import { useSettingContext } from '../../../hooks/contextHooks/useSettingsContext';
import { convertMetersToFeet, convertToCentimeters, convertToFeet } from '../../../functions/helper';
import Incrementer from '../../common/incrementer/incrementer';




export default function Specificpanel({ item, items, setItems, setSpecificItem, index, setIndex, rightPanelOpen, screenshots, setScreenshots}) {

    const [width, setWidth] = React.useState(Math.floor(item.width) || 90);
    const [height, setHeight] = React.useState(Math.floor(item.height) || 90);
    const [imgArr, setImgArr] = useState([]);
    const { id } = useParams();
    const [selectedImage, setSelectedImage] = useState(item.boardIcon ?? "");
    const { measurementSystem } = useSettingContext()

    useEffect(() => {
        const itemNumber = extractNumber(item.boardIcon);
        const loadImage = async () => {
            let arr = getImageArray(itemNumber);
            setImgArr(arr);
        };

        loadImage();
    }, [item]);

    useEffect(() => {
        setWidth(Math.floor(item.width));
        setHeight(Math.floor(item.height));
    }, [items, item]);

    const handleHeight = (val) => {
        let updatedItems = cloneDeep(items);
        updatedItems[index].height += val;
        setItems(updatedItems);
        /*
        let updatedItem = { ...item };
        updatedItem.height += val;
        setSpecificItem(updatedItem);
        */
    }

    const handleWidth = (val) => {
        let updatedItems = cloneDeep(items);
        updatedItems[index].width += val;
        setItems(updatedItems);
        /*
        let updatedItem = { ...item };
        updatedItem.width += val;
        setSpecificItem(updatedItem);
        */
    }

    const adjustHeight = (val) => {
        let updatedItems = cloneDeep(items);
        updatedItems[index].height = val;
        setItems(updatedItems);
    }

    const adjustWidth = (val) => {
        let updatedItems = cloneDeep(items);
        updatedItems[index].width = val;
        setItems(updatedItems);
    }

    const colorMenu = () => {
        return (
            <div style={{
                marginTop: 5
            }}>
                <Row gutter={[16, 16]}>
                    {imgArr.map((img, index) => (
                        <Col span={6} value={index + 1} key={index}>
                            <Button icon={
                                <Image src={img} preview={false} style={{
                                    borderRadius: 3,
                                    display: 'flex',
                                    justify: 'center',
                                    height: 33,
                                    width: 33
                                }} />
                            } style={{
                                height: 41,
                                width: 40
                            }} onClick={() => {
                                let updatedItems = cloneDeep(items);
                                updatedItems.forEach((iterItem) => {
                                    if (iterItem.id === item.id) {
                                        iterItem.boardIcon = img;
                                    }
                                });
                                setItems(updatedItems);
                                setSelectedImage(img);
                            }}
                                className={selectedImage === img ? specificstyle.highlightedButton : ''}
                            >
                            </Button>
                        </Col>
                    ))}
                </Row>

            </div>
        )
    }


    const [material, setMaterial] = React.useState("");

    const panel = () => {
        return (
            <Flex
                vertical
                gap={5}
            >
                <Section className={specificstyle.image}>
                    <span>{item.title}</span>
                    <Divider style={{ marginBottom: 2, marginTop: 12 }} />
                    <span style={{ marginBottom: 12, marginTop: 12, fontSize: '12px', fontWeight: 'normal' }}>{measurementSystem !== 'ft' ? convertToCentimeters(item.description) : convertToFeet(item.description)}</span>
                    <ItemIcon src={item.panelIcon} />
                    {item.title.includes("Carpet") && imgArr.length > 0 &&
                        (
                            <div style={{
                                marginTop: 10
                            }}>{colorMenu()}</div>
                        )
                    }
                </Section>

                {<Section>
                    <span> Size </span>
                    <Divider style={{ marginTop: 12, marginBottom: 12 }} />
                    <ProportionSection
                        height={measurementSystem !== "ft" ? Math.floor(item.height) : convertMetersToFeet(Math.floor(item.height))}
                        width={measurementSystem !== "ft" ? Math.floor(item.width) : convertMetersToFeet(Math.floor(item.width))}
                        setHeight={handleHeight} setWidth={handleWidth} adjustHeight={adjustHeight} adjustWidth={adjustWidth} />
                </Section>}
                {screenshots.length > 0 && <Renders
                    projectId={id}
                    screenshots={screenshots}
                    setScreenshots={setScreenshots}
                />}
                <Section>
                    <Typography variant='h5'>{videos[3].title}</Typography>
                    <Divider style={{ marginBottom: 10, marginTop: 10 }} />
                    <iframe
                        className={styles.videoSection}
                        src={videos[3].video}
                        title="Video player"
                        controls // Adding controls so the user can play manually
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                    ></iframe>
                </Section>

            </Flex>
        )
    }

    const collapseItem = [
        {
            key: '1',
            label: <Typography style={{ fontSize: '16px', color: '#000233', }}>Item Details </Typography>,
            children: <>{panel()}</>,
        }
    ]

    return (
        <Panel className={rightPanelOpen ? styles.itemPanel : styles.itemPanelClosed}>
            {/*rightPanelOpen && <Collapse
                items={collapseItem}
                bordered={false}
                defaultActiveKey={['1']}
                className={specificstyle.collapse}
                expandIconPosition={'end'}
                style={{
                    overflowY: 'auto',
                    maxHeight: 580
                }}
            />*/}
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '12px'
            }}>
                <Typography style={{ fontSize: '16px', color: '#000233', }} > Furniture </Typography>
                {panel()}
            </div>
        </Panel>

    )
}
