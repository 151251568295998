import React from 'react';

import { Layer, Rect, Line } from 'react-konva';

const colors = {
    background: '#FF0000',
    line1: "#E0E0E0",
    line2: "#E2E2E2",
    line3: "#E4E4E4",
};

const strokeWidths = {
    line1: 3,
    line2: 2,
    line3: 1,
};

const PlanGrid = ({ offsetX = 0, offsetY = 0, scale = 1, ...props }) => {
    const viewPort = {
        left: -5000,
        top: -5000,
        right: 5000,
        bottom: 5000,
    };

    const gridWidth = 20;

    let primaryLines = [];
    let secondaryLines = [];
    let tertiaryLines = [];

    // vertical lines
    let x = Math.round(viewPort.left / gridWidth) * gridWidth;
    while (x <= viewPort.right) {
        if (x === 0) {
            primaryLines.push(
                <Line
                    key={`x=${x}`}
                    points={[x, viewPort.top, x, viewPort.bottom]}
                    stroke={colors.line1}
                    strokeWidth={strokeWidths.line1}
                />
            );
        }
        else if (x % 100 === 0) {
            secondaryLines.push(
                <Line
                    key={`x=${x}`}
                    points={[x, viewPort.top, x, viewPort.bottom]}
                    stroke={colors.line2}
                    strokeWidth={strokeWidths.line2}
                />
            );
        }
        else {
            tertiaryLines.push(
                <Line
                    key={`x=${x}`}
                    points={[x, viewPort.top, x, viewPort.bottom]}
                    stroke={colors.line3}
                    strokeWidth={strokeWidths.line3}
                />
            );
        }

        x += gridWidth;
    }

    // horizontal lines
    let y = Math.round(viewPort.top / gridWidth) * gridWidth;
    while (y <= viewPort.bottom) {
        if (y === 0) {
            primaryLines.push(
                <Line
                    key={`y=${y}`}
                    points={[viewPort.left, y, viewPort.right, y]}
                    stroke={colors.line1}
                    strokeWidth={strokeWidths.line1}
                />
            );
        }
        else if (y % 100 === 0) {
            secondaryLines.push(
                <Line
                    key={`y=${y}`}
                    points={[viewPort.left, y, viewPort.right, y]}
                    stroke={colors.line2}
                    strokeWidth={strokeWidths.line2}
                />
            );
        }
        else {
            tertiaryLines.push(
                <Line
                    key={`y=${y}`}
                    points={[viewPort.left, y, viewPort.right, y]}
                    stroke={colors.line3}
                    strokeWidth={strokeWidths.line3}
                />
            );
        }

        y += gridWidth;
    }

    return (
        <Layer width={viewPort.right - viewPort.left} height={viewPort.bottom - viewPort.top}>
            <Rect
                x={viewPort.left}
                y={viewPort.top}
                width={viewPort.width}
                height={viewPort.height}
                fill={colors.background}
            />
            {tertiaryLines}
            {secondaryLines}
            {primaryLines}
        </Layer>
    );
};

export default PlanGrid;