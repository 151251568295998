import { useContext } from "react"
import { ScreenShotContext } from "../../contexts/ScreenshotContext";


export const useScreenshotContext = () => {
    const context = useContext(ScreenShotContext);
    if(!context){
        throw Error('useScreenshotContext must be used inside an ProjectContext Provider ')
    }
    return context;

}