import React, { useState, useEffect, useMemo } from 'react'
import { RegularPolygon, Line, Group, Text } from 'react-konva';
import floorImg1 from '../../assets/Floors/Floor_01.png';
import floorImg2 from '../../assets/Floors/Floor_02.png';
import floorImg3 from '../../assets/Floors/Floor_03.png';
import floorImg4 from '../../assets/Floors/Floor_04.png';
import floorImg5 from '../../assets/Floors/Floor_05.png';
import floorImg6 from '../../assets/Floors/Floor_06.png';
import floorImg7 from '../../assets/Floors/Floor_07.png';
import floorImg8 from '../../assets/Floors/Floor_08.png';
import floorImg9 from '../../assets/Floors/Floor_09.png';
import floorImg10 from '../../assets/Floors/Floor_10.png';
import floorImg11 from '../../assets/Floors/Floor_11.png';
import floorImg12 from '../../assets/Floors/Floor_12.png';
import floorImg13 from '../../assets/Floors/Floor_13.png';
import floorImg14 from '../../assets/Floors/Floor_14.png';
import floorImg15 from '../../assets/Floors/Floor_15.png';
import { useFloorContext } from '../../hooks/contextHooks/useFloorContext';



function Polygon({ index,pointList, selectedFloor,setSelectedFloor,onPanel,floors }) {
    const [patternImage, setPatternImage] = useState(null);
    
    const {floorContext} = useFloorContext();


    
    const memoizedFloor = useMemo(() => {
        // Ensure floorContext and index are properly defined
        if (!Array.isArray(floorContext) || typeof index === 'undefined') {
            return null; // or any fallback value
        }
    
        // Find the data in the floorContext
        let data = floorContext.filter(Boolean).find((item) => item.id === index);
        
    
        // Log or handle the case when data is not found
        if (!data) {
            console.log('Data not found for index:', index);
            return; // or any fallback value
        }
    
        return data;
    }, [floorContext, index,floors]); // Include all relevant dependencies

    const floorParquette = useMemo(() => {
        if(memoizedFloor){
            switch (memoizedFloor.floor_type) {
                case '1':
                    return floorImg1;
                case '2':
                    return floorImg2;
                case '3':
                    return floorImg3;
                case '4':
                    return floorImg4;
                case '5':
                    return floorImg5;
                case '6':
                    return floorImg6;
                case '7':
                    return floorImg7;
                case '8':
                    return floorImg8;
                case '9':
                    return floorImg9;
                case '10':
                    return floorImg10;
                case '11':
                    return floorImg11;
                case '12':
                    return floorImg12;
                case '13':
                    return floorImg13;
                case '14':
                    return floorImg14;
                case '15':
                    return floorImg15;
                default:
                    return floorImg1;
            }
        }
        
    }, [memoizedFloor])




    useEffect(() => {
        const image = new window.Image();
        image.src = floorParquette ?? floorImg1; // Replace with your image path
        image.onload = () => {
            setPatternImage(image);
        };
    }, [floorContext]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (event.target.closest(selectedFloor) === null && !onPanel) {
                // Handle the click outside logic here
                setSelectedFloor(null);
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onPanel]);

    if (!patternImage) {
        return null; // Or some loading indicator
    }

    return (
        <div>
            {patternImage ? (
                <Group>
                    <Line
                        id={selectedFloor}
                        points={pointList}
                        fillPatternImage={patternImage}
                        stroke={"gray"}
                        strokeWidth={0}
                        closed
                        borderRadius={5}
                        onClick={() => setSelectedFloor(index)}
                        
                    />
                </Group>

            ) : (
                <RegularPolygon
                    x={220}
                    y={window.innerHeight / 2}
                    sides={5}
                    radius={70}
                    fill="grey"
                    stroke="black"
                    strokeWidth={4}

                />
            )}
        </div>

    )
}

export default Polygon