import React, { useCallback, useEffect, useState } from 'react'
import styles from './dashboard.module.css';

import { HomeIcon, ArchiveIcon, TutorialIcon } from '../../components/common/icons/icons';
import HStack from '../../components/stack/hStack';
import VStack from '../../components/stack/vStack';
import { Flex, Divider, Button } from 'antd';
import { SettingsIcon } from '../../components/common/icons/icons';
import Section from '../../components/common/section/section';
import IconButton from '../../components/common/buttons/iconButton/iconButton';
import Menu from '../../components/common/menu';
import ActionPanel from '../../components/panels/actionPanel';
import CardPanel from '../../components/panels/cardPanel';
import SettingsForm from '../../components/forms/settingsForm/settingsForm';
import { useAuthContext } from '../../hooks/contextHooks/useAuthContext';
import axios from 'axios';
import { useProjectContext } from '../../hooks/contextHooks/useProjectContext';
import { videos } from '../../data/videocards';
import config from '../../config/config.json';


import VideoPanel from '../../components/panels/videoPanel';
import ProfileAvatar from '../../components/common/avatars/profileAvatar';
import { useUserAPI } from '../../hooks/apiHooks/userHooks/useUserAPI';
import { SettingOutlined } from '@ant-design/icons';



export default function Dashboard() {


    // Hooks
    const { user, dispatch } = useAuthContext();
    const { trigger } = useProjectContext();
    const [selectedSection, setSelectedSection] = useState(0);
    const [projects, setProjects] = useState([]);
    const [screenshots, setScreenshots] = useState([]);
    const [src, setSrc] = useState("");
    const { getUserPhoto } = useUserAPI();
    const [location, setLocation] = useState({});

    let items = [
        { name: "My Projects", icon: (<HomeIcon />) },
        { name: "Tutorials", icon: (<TutorialIcon />) },
        { name: "Archive", icon: (<ArchiveIcon />) }
    ];
    // Callback
    const fetchProjects = useCallback(async () => {
        const res = await axios.get(config.REACT_APP_API_MAIN_ROUTE + "/projects/all/" + user.userIdMongo, {
            headers: {
                Authorization: "Bearer " + user.token
            }
        });
        const resData = res.data
        setProjects(resData.data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trigger, user.token, user.userIdMongo]);

    const fetchScreenshot = useCallback(async () => {
        const res = await axios.get(config.REACT_APP_API_MAIN_ROUTE + "/screenshots/all/" + user.userIdMongo, {
            headers: {
                Authorization: "Bearer " + user.token
            }
        });
        let array = res.data.data;
        let updatedArray = array.map((item) => {
            let data = item.img.data.data;
            const base64String = btoa(
                new Uint8Array(data)
                    .reduce((data, byte) => data + String.fromCharCode(byte), '')
            );
            const mimeType = res.headers['content-type']; // Make sure your backend sets this header
            let newVal = `data:${mimeType};base64,${base64String}`;
            return { ...item, src: newVal };
        })
        setScreenshots(updatedArray);
    }, [user]);

    const fetchProfilePic = useCallback(async () => {
        const res = await getUserPhoto();
        if (res.data.data) {
            let item = (res.data.data);
            let data = item.img.data.data;
            const base64String = btoa(
                new Uint8Array(data)
                    .reduce((data, byte) => data + String.fromCharCode(byte), '')
            );
            const mimeType = res.headers['content-type']; // Make sure your backend sets this header
            let newVal = `data:${mimeType};base64,${base64String}`;
            dispatch({ type: 'GET_PROFILE_PIC', payload: newVal });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //Handlers
    const handleSelectedSettings = () => {
        setSelectedSection(4);
    }

    useEffect(() => {
        fetchProjects();
    }, [fetchProjects]);

    useEffect(() => {
        fetchScreenshot();
    }, [fetchScreenshot])

    useEffect(() => {
        setSelectedSection(0);
    }, [])

    useEffect(() => {
        fetchProfilePic();
    }, []);

    return (
        <div className={styles.app}>
            <HStack>
                <div className={styles.sider}>
                    <VStack>
                        <Section style={{ background: 'rgba(200, 200, 200, 0.15)' }}>
                            <Flex justify='space-between' align='center' gap={10}>
                                <ProfileAvatar letter={user.username.charAt(0)} size={40} src={src} />
                                <span> {user.username ?? user.email} </span>
                               
                                <Button
                                    icon={<SettingsIcon />}
                                    onClick={handleSelectedSettings}
                                    type='text'
                                    style={{
                                        display:'flex',
                                        alignItems:'center',
                                        justifyContent:'center',                        
                                    }}
                                />
                               
                            </Flex>
                        </Section>
                        <Menu items={items} pickFunction={setSelectedSection} picked={selectedSection} />
                    </VStack>
                </div>
                <VStack>
                    {selectedSection === 4 && (
                        <div className={styles.settings}>
                            <VStack>
                                <span> Settings </span>
                                <Divider style={{ margin: 7 }} />
                                <SettingsForm />
                            </VStack>
                        </div>
                    )}
                    {selectedSection !== 4 && (
                        <>
                            {selectedSection === 0 && <div className={styles.getStarted}>
                                <ActionPanel
                                    title={"Get Started"}
                                    description={"Open a new project and transform your ideas into stunning interior designs with Decobot"}
                                />
                            </div>}
                            <div className={styles.myProjects}>
                                {selectedSection === 0 &&
                                    <CardPanel
                                        title={"My Projects"}
                                        items={projects.filter((item) => !item.is_archived)}
                                        screenshots={screenshots}
                                        setScreenshots={setScreenshots}
                                    />}
                                {selectedSection === 1 && <VideoPanel title={"Tutorials"} items={videos} />}
                                {selectedSection === 2 && <CardPanel
                                    title={"Archive"}
                                    items={projects.filter((item) => item.is_archived)}
                                    screenshots={screenshots}
                                    setScreenshots={setScreenshots}
                                />}
                            </div>
                        </>
                    )}
                </VStack>
            </HStack>
        </div>
    )
}
