export const InitialTShaped = {
    points: [
        {
            "x": -300.99996476316437,
            "y": -4.4993221963940755,
            "id": "MC41ODk4MzAx"
        },
        {
            "x": -300.99996476316437,
            "y": 195.50067780360595,
            "id": "MC4wMTg5MzA0"
        },
        {
            "x": -100.99996476316437,
            "y": 195.50067780360592,
            "id": "MC4wODA1MTY4"
        },
        {
            "x": -100.99996476316436,
            "y": 395.5006778036059,
            "id": "MC41NzQ3OTY5"
        },
        {
            "x": 99.00003523683564,
            "y": 395.5006778036059,
            "id": "MC41NzkxNDIw"
        },
        {
            "x": 99.00003523683564,
            "y": 195.50067780360592,
            "id": "MC4xNzk0NDQ3"
        },
        {
            "x": 299.00003523683563,
            "y": 195.50067780360592,
            "id": "MC42Nzg5ODI0"
        },
        {
            "x": 299.00003523683563,
            "y": -4.4993221963940755,
            "id": "MC43MjMyOTYy"
        }
    ],
    walls: [
        {
            "startPointId": "MC4wODA1MTY4",
            "endPointId": "MC41NzQ3OTY5",
            "startPoint": {
                "x": -100.99996476316437,
                "y": 195.50067780360592,
                "id": "MC4wODA1MTY4"
            },
            "endPoint": {
                "x": -100.99996476316436,
                "y": 395.5006778036059,
                "id": "MC41NzQ3OTY5"
            },
            "thickness": 10,
            "color": "#9D9D9B",
            "id": "1"
        },
        {
            "startPointId": "MC41NzQ3OTY5",
            "endPointId": "MC41NzkxNDIw",
            "startPoint": {
                "x": -100.99996476316436,
                "y": 395.5006778036059,
                "id": "MC41NzQ3OTY5"
            },
            "endPoint": {
                "x": 99.00003523683564,
                "y": 395.5006778036059,
                "id": "MC41NzkxNDIw"
            },
            "thickness": 10,
            "color": "#9D9D9B",
            "id": "2"
        },
        {
            "startPointId": "MC41NzkxNDIw",
            "endPointId": "MC4xNzk0NDQ3",
            "startPoint": {
                "x": 99.00003523683564,
                "y": 395.5006778036059,
                "id": "MC41NzkxNDIw"
            },
            "endPoint": {
                "x": 99.00003523683564,
                "y": 195.50067780360592,
                "id": "MC4xNzk0NDQ3"
            },
            "thickness": 10,
            "color": "#9D9D9B",
            "id": "3"
        },
        {
            "startPointId": "MC4xNzk0NDQ3",
            "endPointId": "MC42Nzg5ODI0",
            "startPoint": {
                "x": 99.00003523683564,
                "y": 195.50067780360592,
                "id": "MC4xNzk0NDQ3"
            },
            "endPoint": {
                "x": 299.00003523683563,
                "y": 195.50067780360592,
                "id": "MC42Nzg5ODI0"
            },
            "thickness": 10,
            "color": "#9D9D9B",
            "id": "4"
        },
        {
            "startPointId": "MC42Nzg5ODI0",
            "endPointId": "MC43MjMyOTYy",
            "startPoint": {
                "x": 299.00003523683563,
                "y": 195.50067780360592,
                "id": "MC42Nzg5ODI0"
            },
            "endPoint": {
                "x": 299.00003523683563,
                "y": -4.4993221963940755,
                "id": "MC43MjMyOTYy"
            },
            "thickness": 10,
            "color": "#9D9D9B",
            "id": "5"
        },
        {
            "startPointId": "MC4wODA1MTY4",
            "endPointId": "MC4wMTg5MzA0",
            "startPoint": {
                "x": -100.99996476316437,
                "y": 195.50067780360592,
                "id": "MC4wODA1MTY4"
            },
            "endPoint": {
                "x": -300.99996476316437,
                "y": 195.50067780360595,
                "id": "MC4wMTg5MzA0"
            },
            "thickness": 10,
            "color": "#9D9D9B",
            "id": "6"
        },
        {
            "startPointId": "MC4wMTg5MzA0",
            "endPointId": "MC41ODk4MzAx",
            "startPoint": {
                "x": -300.99996476316437,
                "y": 195.50067780360595,
                "id": "MC4wMTg5MzA0"
            },
            "endPoint": {
                "x": -300.99996476316437,
                "y": -4.4993221963940755,
                "id": "MC41ODk4MzAx"
            },
            "thickness": 10,
            "color": "#9D9D9B",
            "id": "7",
        },
        {
            "startPointId": "MC41ODk4MzAx",
            "endPointId": "MC43MjMyOTYy",
            "startPoint": {
                "x": -300.99996476316437,
                "y": -4.4993221963940755,
                "id": "MC41ODk4MzAx"
            },
            "endPoint": {
                "x": 299.00003523683563,
                "y": -4.4993221963940755,
                "id": "MC43MjMyOTYy"
            },
            "thickness": 10,
            "color": "#9D9D9B",
            "id": "8"
        }
    ]
};

export const InitialZShaped = {
    
        points: [
            {
                "x": -160.99999881074402,
                "y": 296.50499999985857,
                "id": "MC4xMzEzMDgy"
            },
            {
                "x": 49,
                "y": 296.5,
                "id": "MC4wNTE0OTMw"
            },
            {
                "x": 47.95005249606304,
                "y": 191.5052496062828,
                "id": "MC42ODE2Mzgz"
            },
            {
                "x": 152.95005249606305,
                "y": 191.5052496062828,
                "id": "MC4zOTA5NDk0"
            },
            {
                "x": 152.95005249606305,
                "y": -18.494750393717197,
                "id": "MC4yNzY3MzAx"
            },
            {
                "x": -57.04994750393695,
                "y": -18.494750393717172,
                "id": "MC43NzU5MDEx"
            },
            {
                "x": -57.04994750393694,
                "y": 86.50524960628283,
                "id": "MC42MDY5NDUz"
            },
            {
                "x": -161.04994750393695,
                "y": 86.50524960628285,
                "id": "MC45NjE2MTIz"
            }
        ],
        walls: [
            {
                "startPointId": "MC4xMzEzMDgy",
                "endPointId": "MC4wNTE0OTMw",
                "startPoint": {
                    "x": -160.99999881074402,
                    "y": 296.50499999985857,
                    "id": "MC4xMzEzMDgy"
                },
                "endPoint": {
                    "x": 49,
                    "y": 296.5,
                    "id": "MC4wNTE0OTMw"
                },
                "thickness": 10,
                "color": "#9D9D9B",
                "id": "1"
            },
            {
                "startPointId": "MC4wNTE0OTMw",
                "endPointId": "MC42ODE2Mzgz",
                "startPoint": {
                    "x": 49,
                    "y": 296.5,
                    "id": "MC4wNTE0OTMw"
                },
                "endPoint": {
                    "x": 47.95005249606304,
                    "y": 191.5052496062828,
                    "id": "MC42ODE2Mzgz"
                },
                "thickness": 10,
                "color": "#9D9D9B",
                "id": "2"
            },
            {
                "startPointId": "MC42ODE2Mzgz",
                "endPointId": "MC4zOTA5NDk0",
                "startPoint": {
                    "x": 47.95005249606304,
                    "y": 191.5052496062828,
                    "id": "MC42ODE2Mzgz"
                },
                "endPoint": {
                    "x": 152.95005249606305,
                    "y": 191.5052496062828,
                    "id": "MC4zOTA5NDk0"
                },
                "thickness": 10,
                "color": "#9D9D9B",
                "id": "3"
            },
            {
                "startPointId": "MC4zOTA5NDk0",
                "endPointId": "MC4yNzY3MzAx",
                "startPoint": {
                    "x": 152.95005249606305,
                    "y": 191.5052496062828,
                    "id": "MC4zOTA5NDk0"
                },
                "endPoint": {
                    "x": 152.95005249606305,
                    "y": -18.494750393717197,
                    "id": "MC4yNzY3MzAx"
                },
                "thickness": 10,
                "color": "#9D9D9B",
                "id": "4"
            },
            {
                "startPointId": "MC4yNzY3MzAx",
                "endPointId": "MC43NzU5MDEx",
                "startPoint": {
                    "x": 152.95005249606305,
                    "y": -18.494750393717197,
                    "id": "MC4yNzY3MzAx"
                },
                "endPoint": {
                    "x": -57.04994750393695,
                    "y": -18.494750393717172,
                    "id": "MC43NzU5MDEx"
                },
                "thickness": 10,
                "color": "#9D9D9B",
                "id": "5"
            },
            {
                "startPointId": "MC43NzU5MDEx",
                "endPointId": "MC42MDY5NDUz",
                "startPoint": {
                    "x": -57.04994750393695,
                    "y": -18.494750393717172,
                    "id": "MC43NzU5MDEx"
                },
                "endPoint": {
                    "x": -57.04994750393694,
                    "y": 86.50524960628283,
                    "id": "MC42MDY5NDUz"
                },
                "thickness": 10,
                "color": "#9D9D9B",
                "id": "6"
            },
            {
                "startPointId": "MC42MDY5NDUz",
                "endPointId": "MC45NjE2MTIz",
                "startPoint": {
                    "x": -57.04994750393694,
                    "y": 86.50524960628283,
                    "id": "MC42MDY5NDUz"
                },
                "endPoint": {
                    "x": -161.04994750393695,
                    "y": 86.50524960628285,
                    "id": "MC45NjE2MTIz"
                },
                "thickness": 10,
                "color": "#9D9D9B",
                "id": "7"
            },
            {
                "startPointId": "MC45NjE2MTIz",
                "endPointId": "MC4xMzEzMDgy",
                "startPoint": {
                    "x": -161.04994750393695,
                    "y": 86.50524960628285,
                    "id": "MC45NjE2MTIz"
                },
                "endPoint": {
                    "x": -160.99999881074402,
                    "y": 296.50499999985857,
                    "id": "MC4xMzEzMDgy"
                },
                "thickness": 10,
                "color": "#9D9D9B",
                "id": "8"
            }
        ]
    
}
export const InitialRShaped = {
    points : [
        {
            "x": -149,
            "y": 99.5,
            "id": "MC4yMzYxMjk2"
        },
        {
            "x": 151,
            "y": 99.5,
            "id": "MC4zMDAxOTI5"
        },
        {
            "x": 151,
            "y": 299.5,
            "id": "MC44NTcxMTUx"
        },
        {
            "x": -149,
            "y": 299.5,
            "id": "MC41ODI5MDgx"
        },
        
    ],
    walls: [
        {
            "startPointId": "MC41ODI5MDgx",
            "endPointId": "MC44NTcxMTUx",
            "startPoint": {
                "x": -149,
                "y": 299.5,
                "id": "MC41ODI5MDgx"
            },
            "endPoint": {
                "x": 151,
                "y": 299.5,
                "id": "MC44NTcxMTUx"
            },
            "thickness": 10,
            "color": "#9D9D9B",
            "id": "1"
        },
        {
            "startPointId": "MC41ODI5MDgx",
            "endPointId": "MC4yMzYxMjk2",
            "startPoint": {
                "x": -149,
                "y": 299.5,
                "id": "MC41ODI5MDgx"
            },
            "endPoint": {
                "x": -149,
                "y": 99.5,
                "id": "MC4yMzYxMjk2"
            },
            "thickness": 10,
            "color": "#9D9D9B",
            "id": "2"
        },
        {
            "startPointId": "MC4yMzYxMjk2",
            "endPointId": "MC4zMDAxOTI5",
            "startPoint": {
                "x": -149,
                "y": 99.5,
                "id": "MC4yMzYxMjk2"
            },
            "endPoint": {
                "x": 151,
                "y": 99.5,
                "id": "MC4zMDAxOTI5"
            },
            "thickness": 10,
            "color": "#9D9D9B",
            "id": "3"
        },
        {
            "startPointId": "MC4zMDAxOTI5",
            "endPointId": "MC44NTcxMTUx",
            "startPoint": {
                "x": 151,
                "y": 99.5,
                "id": "MC4zMDAxOTI5"
            },
            "endPoint": {
                "x": 151,
                "y": 299.5,
                "id": "MC44NTcxMTUx"
            },
            "thickness": 10,
            "color": "#9D9D9B",
            "id": "4"
        }
    ]
    
}
export const InitialLShaped = {
    points: [
            {
                "x": -202,
                "y": 242.5,
                "id": "MC4zODUxODU0"
            },
            {
                "x": -202,
                "y": 122.5,
                "id": "MC4xNzAxNzcy"
            },
            {
                "x": 38,
                "y": 122.5,
                "id": "MC40NTQxNjU2"
            },
            {
                "x": 38.00000000000001,
                "y": 2.5,
                "id": "MC4zNjcyNjE0"
            },
            {
                "x": 158,
                "y": 2.5,
                "id": "MC44NDU2NTk4"
            },
            {
                "x": 158,
                "y": 242.5,
                "id": "MC44Mzk4Mzc0"
            }
        ],
    walls:
    [
        {
            "startPointId": "MC4zODUxODU0",
            "endPointId": "MC4xNzAxNzcy",
            "startPoint": {
                "x": -202,
                "y": 242.5,
                "id": "MC4zODUxODU0"
            },
            "endPoint": {
                "x": -202,
                "y": 122.5,
                "id": "MC4xNzAxNzcy"
            },
            "thickness": 10,
            "color": "#9D9D9B",
            "id": "1"
        },
        {
            "startPointId": null,
            "endPointId": null,
            "startPoint": {
                "x": -202,
                "y": 122.5,
                "id": "MC4xNzAxNzcy"
            },
            "endPoint": {
                "x": 93.99999999999994,
                "y": 124.5,
                "id": "MC4wODAwODY1"
            },
            "thickness": 10,
            "color": "#9D9D9B",
            "id": "2"
        },
        {
            "startPointId": null,
            "endPointId": null,
            "startPoint": {
                "x": -202,
                "y": 122.5,
                "id": "MC4xNzAxNzcy"
            },
            "endPoint": {
                "x": 48.00000000000003,
                "y": 142.5,
                "id": "MC40NTYwMDMz"
            },
            "thickness": 10,
            "color": "#9D9D9B",
            "id": "3"
        },
        {
            "startPointId": null,
            "endPointId": null,
            "startPoint": {
                "x": -202,
                "y": 122.5,
                "id": "MC4xNzAxNzcy"
            },
            "endPoint": {
                "x": 48,
                "y": 92.5,
                "id": "MC44OTQ1OTgy"
            },
            "thickness": 10,
            "color": "#9D9D9B",
            "id": "4"
        },
        {
            "startPointId": "MC4xNzAxNzcy",
            "endPointId": "MC40NTQxNjU2",
            "startPoint": {
                "x": -202,
                "y": 122.5,
                "id": "MC4xNzAxNzcy"
            },
            "endPoint": {
                "x": 38,
                "y": 122.5,
                "id": "MC40NTQxNjU2"
            },
            "thickness": 10,
            "color": "#9D9D9B",
            "id": "5"
        },
        {
            "startPointId": "MC40NTQxNjU2",
            "endPointId": "MC4zNjcyNjE0",
            "startPoint": {
                "x": 38,
                "y": 122.5,
                "id": "MC40NTQxNjU2"
            },
            "endPoint": {
                "x": 38.00000000000001,
                "y": 2.5,
                "id": "MC4zNjcyNjE0"
            },
            "thickness": 10,
            "color": "#9D9D9B",
            "id": "6"
        },
        {
            "startPointId": "MC4zNjcyNjE0",
            "endPointId": "MC44NDU2NTk4",
            "startPoint": {
                "x": 38.00000000000001,
                "y": 2.5,
                "id": "MC4zNjcyNjE0"
            },
            "endPoint": {
                "x": 158,
                "y": 2.5,
                "id": "MC44NDU2NTk4"
            },
            "thickness": 10,
            "color": "#9D9D9B",
            "id": "7"
        },
        {
            "startPointId": "MC44NDU2NTk4",
            "endPointId": "MC44Mzk4Mzc0",
            "startPoint": {
                "x": 158,
                "y": 2.5,
                "id": "MC44NDU2NTk4"
            },
            "endPoint": {
                "x": 158,
                "y": 242.5,
                "id": "MC44Mzk4Mzc0"
            },
            "thickness": 10,
            "color": "#9D9D9B",
            "id": "8"
        },
        {
            "startPointId": "MC44Mzk4Mzc0",
            "endPointId": "MC4zODUxODU0",
            "startPoint": {
                "x": 158,
                "y": 242.5,
                "id": "MC44Mzk4Mzc0"
            },
            "endPoint": {
                "x": -202,
                "y": 242.5,
                "id": "MC4zODUxODU0"
            },
            "thickness": 10,
            "color": "#9D9D9B",
            "id": "9"
        }
    ]
}