import { Image, Space , message  } from 'antd'
import React, { useEffect, useState } from 'react'
import {
    DownloadOutlined,
    DeleteOutlined,
    RotateLeftOutlined,
    RotateRightOutlined,
    SwapOutlined,
    ZoomInOutlined,
    ZoomOutOutlined,
} from '@ant-design/icons';
import styles from './screenshotModal.module.css';
import axios from 'axios';
import config from '../../../config/config.json';
import { useAuthContext } from '../../../hooks/contextHooks/useAuthContext';


function ScreenshotModal({ screenshots,setScreenshots , ssIndex = -1}) {
    const {user} = useAuthContext();
    const [selectedIndex, setSelectedIndex] = useState(0);

    const onDelete = async () => {
        const selectedImg = screenshots[selectedIndex];
        try {
            const response = await axios.delete(config.REACT_APP_API_MAIN_ROUTE + '/screenshots/' + selectedImg._id, {
                headers : {
                    Authorization : "Bearer " + user.token
                },
                data:{
                    userId:user.userIdMongo
                }
            });
            message.success(response.data.message);
            // Optionally, remove the deleted image from the screenshots array
            // Assuming screenshots is a state, you would need to update it here
            setScreenshots(prevScreenshots => prevScreenshots.filter(img => img._id !== selectedImg._id));
        } catch (error) {
            console.error(error);
            message.error('Failed to delete the screenshot.');
        }
    };

    const onDownload = () => {
        const link = document.createElement('a');
        link.href = screenshots[selectedIndex].src;
        link.download = 'downloaded-image.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        if(selectedIndex >= screenshots.length){
            setSelectedIndex(screenshots.length - 1);
        }
    },[screenshots]);

    return (
        <Image.PreviewGroup preview={{
            current: selectedIndex,
            onChange: (current) => setSelectedIndex(current),
            toolbarRender: (
                _,
                {
                    transform: { scale },
                    actions: {
                        onFlipY,
                        onFlipX,
                        onRotateLeft,
                        onRotateRight,
                        onZoomOut,
                        onZoomIn,
                    },
                },
            ) => (
                <Space size={20} className={styles.toolbarWrapper}>
                    <DownloadOutlined onClick={onDownload} />
                    <DeleteOutlined onClick={onDelete} />
                    <SwapOutlined rotate={90} onClick={onFlipY} />
                    <SwapOutlined onClick={onFlipX} />
                    <RotateLeftOutlined onClick={onRotateLeft} />
                    <RotateRightOutlined onClick={onRotateRight} />
                    <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                    <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                </Space>
            ),
        }}>
            {screenshots.map((item, index) => (
                <Image
                    id={`hidden-img-${item._id}`}
                    src={item.src}
                    key={index}
                    onClick={() => setSelectedIndex(ssIndex !== -1 ? ssIndex : index)}
                />
            ))}
        </Image.PreviewGroup>
    )
}

export default ScreenshotModal
