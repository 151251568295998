import { createContext, useReducer } from 'react';

export const FloorContext = createContext();

export const floorReducer = (state,action) => {
    switch(action.type){
        case 'GET_FLOORS':
            return { ...state, floorContext: action.payload }
        case 'TRIGGER_FLOOR':
            return { ...state, floorTrigger: state.floorTrigger + 1}
        default:
            return state;
    }
}

export const FloorContextProvider = ({children}) => {
    const [state,floorDispatch] = useReducer(floorReducer, {
        floorContext: [],
        floorTrigger: 0
    })
    
    return (
        <FloorContext.Provider value={{...state,floorDispatch}}>
            {children}
        </FloorContext.Provider>
    )
}