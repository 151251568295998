import React from 'react'
import { Divider, Flex } from 'antd'
import Incrementer from '../../common/incrementer/incrementer'
import { useSettingContext } from '../../../hooks/contextHooks/useSettingsContext'

export default function ProportionSection({height,width,setHeight,setWidth,adjustHeight,adjustWidth}) {
  const {measurementSystem} = useSettingContext();
  return (
   
        <Flex vertical gap={3}>
            <Incrementer 
              name={measurementSystem !== 'ft' ? "Depth (cm)" : "Depth (ft)"} data={width} 
              increment={() => setWidth(1)} 
              decrement={() => setWidth(-1)}
              adjustValue={(value) => adjustWidth(value)}
              />
            <Divider style={{marginTop:12,marginBottom:12}}/>
            <Incrementer 
              name={measurementSystem !== 'ft' ? "Width (cm)" : "Width (ft)"} 
              data={height}
              increment={() => setHeight(+1)}
              decrement={() => setHeight(-1)}
              adjustValue={(value) => adjustHeight(value)}
            />
        </Flex>

  )
}
