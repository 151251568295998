import React from 'react';
import { Button, Upload, message } from 'antd';

import { useUserAPI } from '../../../../hooks/apiHooks/userHooks/useUserAPI';
import { useAuthContext } from '../../../../hooks/contextHooks/useAuthContext';


function UploadButton() {
    const { getUserPhoto, uploadUserPhoto } = useUserAPI()
    const { dispatch } = useAuthContext();

    const handlePhotoUpload = async ({ file }) => {
        try {
            await uploadUserPhoto({ file });
            message.success("Profile photo uploaded successfully");
            const res = await getUserPhoto();
            if (res.data.data) {
                let item = (res.data.data);
                let data = item.img.data.data;
                const base64String = btoa(
                    new Uint8Array(data)
                        .reduce((data, byte) => data + String.fromCharCode(byte), '')
                );
                const mimeType = res.headers['content-type']; // Make sure your backend sets this header
                let newVal = `data:${mimeType};base64,${base64String}`;
                dispatch({ type: 'GET_PROFILE_PIC', payload: newVal });
            }

        }
        catch (e) {
            message.error("Profile Photo Could not be updated");
        }

    };

    const beforeUpload = (file) => {
        const isLt15M = file.size / 1024 / 1024 < 15; // limit file size to 15MB
        if (!isLt15M) {
            message.error('Image must smaller than 15MB!');
            return Upload.LIST_IGNORE;
        }
        return true;
    };

    return (
        <Upload showUploadList={false} customRequest={handlePhotoUpload} accept='.jpeg,.jpg,.png' beforeUpload={beforeUpload}>
            <Button type='primary' size='large'>Click to Upload</Button>
        </Upload>
    )
}

export default UploadButton
