import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthContextProvider } from './contexts/AuthContext';
import { ProjectContextProvider } from './contexts/ProjectContext';
import { ScreenshotContextProvider } from './contexts/ScreenshotContext';
import {
  GOOGLE_CLIENT_ID
} from './shared/data/googleDatas';
import { DragProvider } from './contexts/DragContext';
import { SnapShotContextProvider } from './contexts/SnapshotContext';
import { FloorContextProvider } from './contexts/FloorContext';
import { SettingsContextProvider } from './contexts/SettingsContext';
import config from './config/config.json';

const googleTagId = config.REACT_APP_GOOGLE_TAG_ID;

export const initializeGoogleTagManager = () => {
  if (googleTagId && window.location.hostname === 'app.decobot.ai') {
    const scriptTag = document.createElement('script');
    scriptTag.async = true;
    scriptTag.src = `https://www.googletagmanager.com/gtag/js?id=${googleTagId}`;
    document.head.appendChild(scriptTag);

    const scriptInit = document.createElement('script');
    scriptInit.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${googleTagId}');
    `;
    document.head.appendChild(scriptInit);
    console.log("Google Tag Manager ID");
  } else {
    console.log("Google Tag Manager ID is not defined.");
  }
};

initializeGoogleTagManager();
const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <AuthContextProvider>
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <ProjectContextProvider>
        <SettingsContextProvider>
          <SnapShotContextProvider>
            <ScreenshotContextProvider>
              <FloorContextProvider>
                <DragProvider>
                  <React.StrictMode>
                    <App />
                  </React.StrictMode>
                </DragProvider>
              </FloorContextProvider>
            </ScreenshotContextProvider>
          </SnapShotContextProvider>
        </SettingsContextProvider>
      </ProjectContextProvider>
    </GoogleOAuthProvider>
  </AuthContextProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();