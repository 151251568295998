import React from 'react';

// styles
import styles from './section.module.css';

export default function Section({ icon, title, children, className, ...props }) {
    return (
        <div className={[styles.section, className].filter(Boolean).join(' ')} {...props}>
            <div className={styles.title}>
                {icon}
                {title}
            </div>
            {children}
        </div>
    );
}
