import React, { useEffect } from 'react';
import { Flex } from 'antd';
import styles from '../../../../pages/signin/signin.module.css';
import Image from 'antd';
import img from './interactiveImg.png';
export default function InteractiveSphere() {
    

    return (
        <Flex
            direction="column"
            align="center"
            justify="center"
            style={{
                height: "100vh",
                width: "50vw",
                background: "transparent",
                position: "relative",
                backgroundColor:'#7230FF'
            }}
        >
            <div>
                <img src={img} width={'95%'}/> 
            </div>
            <div
                style={{
                    position: "absolute",
                    zIndex: 1,
                    top: "2px",
                }}
            >
                <Flex vertical align='flex-start' justify='flex-start' style={{
                    width: "50vw",
                    gap: 20,
                    padding: '7vh',
                    alignItems:'center'
                }}>

                    <span className={styles.title}>Start Designing Your House</span>
                    <span className={styles.subheader}>
                        Create your perfect house with just a few clicks!
                    </span>

                </Flex>

            </div>
        </Flex>

    )
}
