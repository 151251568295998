import React from 'react';
import styles from './row.module.css';



export default function Row({ children , colsPerRow }) {
    const colStyle = {
        flex: `0 0 ${100 /( colsPerRow + 1)}%`, // Distribute columns equally, adjust as needed
    };
    return (
        <div className={styles.row}>
            {React.Children.map(children, (child, index) => (
                <div key={index} style={colStyle}>
                    {child}
                </div>
            ))}
        </div>
    )
}