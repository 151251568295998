import React, { useEffect } from 'react'
import Row from '../../grid/row';
import Col from '../../grid/col';
import { Divider, Empty } from 'antd';
import styles from './videoPanel.module.css';
import Videocard from '../../cards/videocard';

export default function VideoPanel({title,items}) {

    return (
        <div className={styles.mainPanel}>
            <span className={styles.title}> {title} </span>
            <Divider style={{ margin: 5 }} />
            {items && items.length > 0 && (
                <div className={styles.fullList}>
                    <Row colsPerRow={4}>
                        {items.map((data, index) => (
                            <Col key={index}>
                                <Videocard title={data.title} video={data.video}/> 
                            </Col>
                        ))}
                    </Row>
                </div>
            )}
            {items.length === 0 && (
                <div className={styles.emptyList}>
                    <Empty
                        imageStyle={{ height: 200 }}
                        description={
                            <span>
                                {title === 'Tutorials' ? "No Tutorials" :"No Projects"}
                            </span>
                        }
                    ></Empty>
                </div>

            )}
        </div>
    )
}
