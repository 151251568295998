import React, { useEffect, useState } from 'react';
import { Group, Image } from 'react-konva';
import useImage from 'use-image';

import cloneDeep from 'lodash/cloneDeep';

export default function DragItem({ shapeRef, handleTransform, imgUrl, x, y, height, width, rotation, setDragCount, onUpdatePosition, onUpdateHeightAndWidth, isDraggable, index, select, zIndex, itemCategory, walls, points, offsetX, offsetY, dragX, dragY, scale, doorDirection, scaleX, scaleY, items, setItems,selectedWall, setClickedItem, setDraggedItem }) {
    const [dragging, setDragging] = useState(false);
    const [currentRotation, setCurrentRotation] = useState(rotation || 0);
    const [image] = useImage(imgUrl);
    const [closestWall, setClosestWall] = useState(null);

    const isWindowOrDoor = (itemCategory) => {
        return itemCategory.toLocaleLowerCase().includes("window") || (itemCategory.toLocaleLowerCase().includes("door") && !itemCategory.toLocaleLowerCase().includes("shower"));
    }

    const getPoint = (id) => {
        let index = points.findIndex(point => point?.id === id);
        if (index === -1)
            return null;
        return points[index];
    }

    const calculateDistanceToWall = (wall, point) => {
        const startPoint = getPoint(wall.startPointId);
        const endPoint = getPoint(wall.endPointId);
        const [x1, y1] = [startPoint.x, startPoint.y];
        const [x2, y2] = [endPoint.x, endPoint.y];
        const [px, py] = [point.x, point.y];

        const A = px - x1;
        const B = py - y1;
        const C = x2 - x1;
        const D = y2 - y1;

        const dot = A * C + B * D;
        const len_sq = C * C + D * D;
        const param = len_sq !== 0 ? dot / len_sq : -1;

        let xx, yy;

        if (param < 0) {
            xx = x1;
            yy = y1;
        } else if (param > 1) {
            xx = x2;
            yy = y2;
        } else {
            xx = x1 + param * C;
            yy = y1 + param * D;
        }

        const dx = px - xx;
        const dy = py - yy;

        return Math.sqrt(dx * dx + dy * dy);
    };

    const findClosestWall = (mousePosition) => {
        let minDistance = 55;  // Threshold for attaching to a wall
        let closest = null;
        walls.forEach((wall) => {
            const distance = calculateDistanceToWall(wall, mousePosition);
            if (distance < minDistance) {
                minDistance = distance;
                closest = wall;
            }
        });

        setClosestWall(closest);
    };

    const attachToWall = (wall, itemX, itemY) => {
        if(!shapeRef.current)
            return;
        const startPoint = getPoint(wall.startPointId);
        const endPoint = getPoint(wall.endPointId);
        
        const [x1, y1] = [startPoint.x, startPoint.y];
        const [x2, y2] = [endPoint.x, endPoint.y];

        const wallVector = { x: x2 - x1, y: y2 - y1 };
        const wallLength = Math.sqrt(wallVector.x * wallVector.x + wallVector.y * wallVector.y);

        const normWallVector = {
            x: wallVector.x / wallLength,
            y: wallVector.y / wallLength,
        };

        const projLength = (itemX - x1) * normWallVector.x + (itemY - y1) * normWallVector.y;

        const clampedProjLength = Math.max(0, Math.min(projLength, wallLength));

        const constrainedX = x1 + clampedProjLength * normWallVector.x;
        const constrainedY = y1 + clampedProjLength * normWallVector.y;

        let thicknessAdjustmentX = wall.thickness / 2 * normWallVector.y; 
        let thicknessAdjustmentY = wall.thickness / 2 * -normWallVector.x;  

        const angleRad = Math.atan2(y2 - y1, x2 - x1);
        let angleDeg = angleRad * (180 / Math.PI);

        if (doorDirection) {
            thicknessAdjustmentX *= -1;
            thicknessAdjustmentY *= -1;
            angleDeg += 180;
        }

        shapeRef.current.offsetX(width / 2);
        shapeRef.current.offsetY(0);

        shapeRef.current.position({
            x: constrainedX + thicknessAdjustmentX,
            y: constrainedY + thicknessAdjustmentY,
        });

        shapeRef.current.rotation(angleDeg);

        if (isWindowOrDoor(itemCategory) && itemCategory.toLocaleLowerCase().includes("window")) {
            shapeRef.current.height(wall.thickness);
            
            height = wall.thickness;
        }

        setCurrentRotation(angleDeg);
    };

    const handleDragStart = () => {
        setDragging(true);
    };

    const handleDragMove = (e) => {
        
        const { clientX, clientY } = e.evt;
        const mousePoint = {
            x: (clientX + offsetX - dragX) / scale,
            y: (-1 * (clientY + offsetY - dragY)) / scale,
        };

        if (isWindowOrDoor(itemCategory)) {
            findClosestWall(mousePoint);

            if (closestWall) {
                attachToWall(closestWall, mousePoint.x, mousePoint.y);
            }
        }
    };

    const handleDragEnd = () => {
        setDraggedItem(true);
        setDragging(false);
        setDragCount((prev) => prev + 1);
        const shapeElement = shapeRef.current;
        onUpdatePosition();
        setCurrentRotation(shapeElement.rotation());
        //handleTransform();
    };

    const onTransformEnd = () => {
        //handleTransform();
        const newWidth = shapeRef.current.width() * shapeRef.current.scaleX();
        const newHeight = shapeRef.current.height() * shapeRef.current.scaleY();


        onUpdateHeightAndWidth();

        shapeRef.current.scaleX(1);
        shapeRef.current.scaleY(1);
        shapeRef.current.width(newWidth);
        shapeRef.current.height(newHeight);

        if (isWindowOrDoor(itemCategory) && closestWall) {
            shapeRef.current.rotation(currentRotation);
        }
    };

    useEffect(() => {
        if(!shapeRef?.current)
            return;
        if (isWindowOrDoor(itemCategory)) {
            if(!closestWall){
                const mousePoint = {
                    x: shapeRef.current.x(),
                    y: shapeRef.current.y(),
                };
                findClosestWall(mousePoint);
            }
            if(!closestWall)
                return;
            
            attachToWall(closestWall, shapeRef.current.x(), shapeRef.current.y());
            const updatedItems = items;
            if(updatedItems[index].x == shapeRef.current.x() && updatedItems[index].y == shapeRef.current.y())
                return;
            updatedItems[index].rotation = shapeRef.current.rotation();
            updatedItems[index].x = shapeRef.current.x();
            updatedItems[index].y = shapeRef.current.y();
        }
    }, [walls]);

    return (
        <Group>
            <Image
                ref={shapeRef}
                onTransformEnd={onTransformEnd}
                image={image}
                draggable={isDraggable}
                x={x}
                y={y}
                offsetX={width/2}
                offsetY={height/2}
                rotation={currentRotation}
                width={width}
                height={height}
                onDragStart={handleDragStart}
                onDragMove={handleDragMove}
                onDragEnd={handleDragEnd}
                onClick={() => {select(index); setClickedItem(index)}}
                scaleX={scaleX}
                scaleY={scaleY}
            />
        </Group>
    );
}
